import React, {useEffect, useRef, useState} from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import freezeIcon from '../../theme/assets/svg/h2_index_freeze_icon.svg';
import {
    clearIndexList,
    freezeWithdraw,
    setFreezeReinvest,
    setIndexFreezeModalStatus
} from "../../store/cabinet/action";
import Moment from "react-moment";
import EmptyValues from "../_UI/emtyValues";
import {toast} from "react-toastify";
import {floorToDecimals} from "../../helpers/floorHandler";



const IndexFreeze = (props) => {
    const {scrollAction} = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scrollRef = useRef(null);

    const activeFreeze = useSelector(store => store.cabinet.activeFreeze);
    const freezeWithdrawStatusStarted = useSelector(store => store.cabinet.freezeWithdrawStatusStarted);
    const freezeTotal = useSelector(store => store.cabinet.freezeTotal);
    const clearIndexListStatus = useSelector(store => store.cabinet.clearIndexListStatus);




    const [list, setList] = useState([]);

    const statusHandler = (status) =>{
        switch (status){
            case 'new':
                return  t('Новый')
            case 'active':
                return t('Активный')
            case 'closed':
                return t('Завершенный')
            default:
                return t('Неизвестно')
        }
    }

    const reinvestHandler = (index, isChangeable, id) =>{
        if (isChangeable){
            dispatch(setFreezeReinvest(id))
            const newList = [...list];
            newList[index].auto_reinvest = !newList[index].auto_reinvest;
            setList(newList)
        }
    }

    const isWithdrawSuccess = (pendingStatus, profit, availableStatus) =>{
        return !pendingStatus && Number(profit) > 0 && availableStatus
    }

    const withdrawBtnHandler = (pendingStatus, profit, id, index, availableStatus) =>{
        const successCallback = () =>{
            toast(t('Ваша заявка на вывод успешно отправлена! Ожидайте завершения транзакции.'));
            const newList = [...list];
            newList[index].is_pending_withdraw = true;
            setList(newList)
        }

        if (isWithdrawSuccess(pendingStatus, profit, availableStatus) && !freezeWithdrawStatusStarted){
            dispatch(freezeWithdraw({amount: profit}, id, successCallback))
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                if (Math.ceil(scrollTop) + clientHeight >= scrollHeight) {
                    scrollAction(true);
                } else {
                    scrollAction(false);
                }
            }
        };

        const currentRef = scrollRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollAction]);

    useEffect(()=>{
        if (clearIndexListStatus){
            setList([]);
            dispatch(clearIndexList(false));
        }
    },[clearIndexListStatus, dispatch])

    useEffect(()=>{
        setList([...list, ...(activeFreeze.data || [])] || []);
    },[activeFreeze]) // eslint-disable-line

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.contentWrap}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.imgWrap}`}>
                            <img src={freezeIcon} alt="Freeze"/>
                        </div>
                        <div className={`${Styles.titleWSF}`}><span className={`${Styles.titleWSF}`}>{t('Генерация')}</span> {t('ликвидности')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        <div className={`${Styles.freezeRow}`}>
                            <div className={`${Styles.freezeWrap}`}>
                                <div className={`${Styles.freezeNote}`}>
                                    {t('Зарабатывать на INDEX OMT20 стало еще проще! Заморозьте свои токены, чтобы заработать еще больше на абсолютном пассиве')}!
                                </div>
                                <div className={`${Styles.button} ${Styles.freezeBtn}`} onClick={()=>{dispatch(setIndexFreezeModalStatus(true))}}>{t('Заморозить')}</div>
                            </div>
                        </div>
                        <div className={`${Styles.dataRow}`}>
                            <div className={`${Styles.dataTitle} ${Styles.titleWSF}`}>{t('Информация о вашем заработке')}</div>
                            <div className={`${Styles.dataWrap}`}>
                                <div className={`${Styles.dataWrapItem}`}>
                                    <div className={`${Styles.dataWrapItemName} ${Styles.titleWSF}`}>{t('Заработано')}</div>
                                    <div className={`${Styles.dataWrapItemValue}`}>{freezeTotal?.earned ? floorToDecimals(freezeTotal?.earned, 6) : '0'} OMT20</div>
                                </div>
                                <div className={`${Styles.dataWrapItem}`}>
                                    <div className={`${Styles.dataWrapItemName} ${Styles.titleWSF}`}>{t('В работе')}</div>
                                    <div className={`${Styles.dataWrapItemValue}`}>{freezeTotal?.in_work ? floorToDecimals(freezeTotal?.in_work, 6) : '0'} OMT20</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.stakingWrap} ${Styles.cardBodyWithScroll}`}>
                        <div className={`${Styles.stakingTitleRow}`}>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Дата заморозки')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Сумма')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Месяцы')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>APY</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Дата окончания')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Авто-реинвест')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Действие')}</div>
                        </div>
                        <div className={`${Styles.stakingBox}`} ref={scrollRef}>
                            {list.length ? list.map((item, index)=>{
                                return (
                                    <div className={`${Styles.stakingBoxItem}`} key={'freeze' + index}>
                                        <div className={`${Styles.stakingBoxItemWrap}`}>
                                            <div className={`${Styles.stakingBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Дата заморозки')}</div>
                                            <div className={`${Styles.stakingBoxItemWrapValue} ${Styles.stakingBoxItemWrapValueBlue}`}><Moment format="DD/MM/YYYY">{item.date}</Moment></div>
                                        </div>
                                        <div className={`${Styles.stakingBoxItemWrap}`}>
                                            <div className={`${Styles.stakingBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Сумма')}</div>
                                            <div className={`${Styles.stakingBoxItemWrapValue} ${Styles.stakingBoxItemWrapValueOrange}`}>{Number(item.total).toFixed(6) * 1} {item?.currency?.short_name ? item?.currency?.short_name : item?.currency?.name}</div>
                                        </div>
                                        <div className={`${Styles.stakingBoxItemWrap}`}>
                                            <div className={`${Styles.stakingBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Месяцы')}</div>
                                            <div className={`${Styles.stakingBoxItemWrapValue}`}>{item.days / 30}</div>
                                        </div>
                                        <div className={`${Styles.stakingBoxItemWrap}`}>
                                            <div className={`${Styles.stakingBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>APY</div>
                                            <div className={`${Styles.stakingBoxItemWrapValue}`}>{item.apy * 1}%</div>
                                        </div>
                                        <div className={`${Styles.stakingBoxItemWrap}`}>
                                            <div className={`${Styles.stakingBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Дата окончания')}</div>
                                            <div className={`${Styles.stakingBoxItemWrapValue}`}><Moment format="DD/MM/YYYY">{item.end_at}</Moment></div>
                                        </div>
                                        <div className={`${Styles.stakingBoxItemWrap}`}>
                                            <div className={`${Styles.stakingBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                                            <div className={`${Styles.stakingBoxItemWrapValue}`}>{statusHandler(item.status)}</div>
                                        </div>
                                        <div className={`${Styles.stakingBoxItemWrap}`}>
                                            <div className={`${Styles.stakingBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Авто-реинвест')}</div>
                                            <div className={`${Styles.stakingBoxItemWrapValue} ${Styles.stakingBoxItemWrapValueWB}`}>
                                                <div className={`${Styles.investBox}`}>
                                                    <input type="checkbox" onChange={() => {reinvestHandler(index, item.auto_reinvest_changeable, item.id)}} checked={item.auto_reinvest}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${Styles.stakingBoxItemWrap}`}>
                                            <div className={`${Styles.stakingBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Действие')}</div>
                                            <div
                                                className={`${Styles.stakingBoxItemWrapValue} ${Styles.stakingBoxItemWrapValueYellow} ${isWithdrawSuccess(item.is_pending_withdraw, item.profit, item.withdraw_available) && !freezeWithdrawStatusStarted ? null : Styles.stakingBoxItemWrapValueDisable}`}
                                                onClick={()=>{withdrawBtnHandler(item.is_pending_withdraw, item.profit, item.id, index, item.withdraw_available)}}
                                            >
                                                {t('Вывод')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <EmptyValues text={t('У вас пока что нет замороженных активов')} withIcon={false} withBtn={false}/>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IndexFreeze;
