import React, {useCallback, useEffect, useState, useRef} from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {spaceHandler} from "../../helpers/dotsHandler";
import * as echarts from "echarts/core";
import ReactECharts from "echarts-for-react";
import dropArrow from '../../theme/assets/svg/omt_index_drop_arrowY.svg';
import {getBotProfit} from "../../store/cabinet/action";


const CabinetBotProfit = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const chartRef = useRef(null);

    const botProfit = useSelector(store => store.cabinet.botProfit);
    const botMainData = useSelector(store => store.cabinet.botMainData);

    const [correctData, setCorrectData] = useState(false);
    const [currentPeriod, setCurrentPeriod] = useState('day');
    const [dropStatus, setDropStatus] = useState(false);

    const option = {
        color: ['#FFD600'],
        // responsive: true,
        // maintainAspectRatio: false,
        tooltip: {
            formatter: function (params) {
                return (
                    `<div style="background-color: rgba(0,0,0,0.7); color: white; padding: 10px; border: 10px solid #FFD600;">
                        <span style="font-weight: bold;">Date:</span> ${params[0].name}<br/>
                        <span style="font-weight: bold;">Price:</span> ${spaceHandler(params[0].value * 1, true)}<br/>
                    </div>`
                );
            },
            extraCssText: 'padding: 0; border-color: #FFD600;',
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            },
        },
        legend: false,
        grid: {
            left: '62px',
            right: '30px',
            bottom: '20px',
            height: '250px',
            containLabel: false
        },

        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: correctData ? correctData?.names : ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                show: true,
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: true,
                    color: '#8C9497',
                    // color: '#FFFFFF',
                    fontSize: 10,
                    // interval: function(index, value) {
                    //     // Всегда показывать первую и последнюю метки
                    //     const dataCount = correctData ? correctData.names.length : 7;
                    //     return index === 0 || index === dataCount - 1 || (index % Math.floor(dataCount / 5) === 0);
                    // },
                    formatter: function (value, index) {
                        const dataCount = correctData ? correctData.names.length : 7;
                        const showInterval = Math.floor((dataCount - 1) / 4); // Определяем количество промежуточных меток

                        if (index === 0 || index === dataCount - 1 || index % showInterval === 0) {
                            return value;
                        }
                        return '';
                    },
                }
            },
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#3c432c',
                        opacity: 1
                    }
                },
                axisLabel: {
                    show: true,
                    color: '#FFFFFF',
                    fontSize: 14,
                    formatter: function(value) {
                        return `$${spaceHandler(value, true)}`;
                    }
                }
            }
        ],
        series: [
            {
                name: 'Value (USD)',
                type: 'line',
                stack: 'Total',
                smooth: false,
                lineStyle: {
                    color: '#FFD600',
                    width: 2,
                    shadowColor: 'rgba(255, 214, 0, 0.9)',  // Более непрозрачный цвет свечения
                    shadowBlur: 20,                   // Размытие свечения
                    shadowOffsetX: 0,
                    shadowOffsetY: 0
                },
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(255, 214, 0, 0.40)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(153, 129, 0, 0.00)'
                        }
                    ])
                },
                emphasis: {
                    focus: 'series'
                },
                connectNulls: true,
                data: correctData ? correctData?.values : [0, 0, 0, 0, 0, 0, 0]
            }
        ]
    };

    const graphDataHandler = useCallback((data)  =>{
        let names = [];
        let values = [];
        let maxValue = 0;

        Object.keys(data).sort().forEach(key=>{
            names.push(key);
            values.push(data[key]);
        })

        if (names.length > 0 && values.length > 0){
            setCorrectData({ names: [...names], values: [...values], maxValue });
        }
    }, [])

    const changePeriodHandler = (period) =>{
        setCurrentPeriod(period);
        setDropStatus(false);
        dispatch(getBotProfit(botMainData?.bot?.id, period));
    }

    const periodBtnTextHandler = (period) =>{
        switch (period){
            case 'hour':
                return t('Час');
            case 'day':
                return t('День');
            case 'week':
                return t('Неделя');
            case 'month':
                return t('Месяц');
            default:
                return 'Unknown'
        }
    }

    useEffect(()=>{
        if (botProfit){
            graphDataHandler(botProfit.history)
        }
    },[graphDataHandler, botProfit])

    useEffect(()=>{
        if (botProfit){
            graphDataHandler(botProfit.history)
        }
    },[graphDataHandler, botProfit])

    useEffect(() => {
        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.getEchartsInstance().resize();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                <div className={`${Styles.titleWSF}`}>{t('Доход')} <span className={`${Styles.titleWSF}`}>{t('за месяц')}</span></div>
                <div className={`${Styles.periodRow}`}>
                    <div className={`${Styles.periodBox}`}>
                        <div className={`${Styles.periodBoxName}`}>{t('Период')}:</div>
                        <div className={`${Styles.periodBoxBtn}`} onClick={() => {
                            setDropStatus(!dropStatus)
                        }}>
                            <div className={`${Styles.periodBoxBtnText}`}>{periodBtnTextHandler(currentPeriod)}</div>
                            <div className={`${Styles.periodBoxBtnImgWrap}`}>
                                <img src={dropArrow} alt="drop arrow"/>
                            </div>
                            <div className={`${Styles.periodBoxBtnDropContent} ${dropStatus ? Styles.periodBoxBtnDropContentActive : null}`}>
                                <div
                                    className={`${Styles.periodBoxBtnDropContentItem} ${currentPeriod === 'day' ? Styles.periodBoxBtnDropContentItemActive : null}`}
                                    onClick={() => {
                                        changePeriodHandler('day')
                                    }}
                                >
                                    {t('День')}
                                </div>
                                <div
                                    className={`${Styles.periodBoxBtnDropContentItem} ${currentPeriod === 'week' ? Styles.periodBoxBtnDropContentItemActive : null}`}
                                    onClick={() => {
                                        changePeriodHandler('week')
                                    }}
                                >
                                    {t('Неделя')}
                                </div>
                                <div
                                    className={`${Styles.periodBoxBtnDropContentItem} ${currentPeriod === 'month' ? Styles.periodBoxBtnDropContentItemActive : null}`}
                                    onClick={() => {
                                        changePeriodHandler('month')
                                    }}
                                >
                                    {t('Месяц')}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className={`${Styles.priceBox}`}>*/}
                    {/*    <div className={`${Styles.priceBoxName}`}>{t('Текущая цена')}:</div>*/}
                    {/*    <div*/}
                    {/*        className={`${Styles.priceBoxValue}`}>${indexMainData?.index?.rate ? spaceHandler(indexMainData?.index?.rate * 1, true) : '0'}</div>*/}
                    {/*</div>*/}
                </div>
                <div className={`${Styles.graphWrap}`}>
                    <ReactECharts ref={chartRef} option={option} style={{height: '300px', width: '100%'}}/>
                </div>
            </div>
        </section>
    );
};

export default CabinetBotProfit;


