import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import ReactSlider from 'react-slider';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {
    clearIndexList,
    freezeToken,
    freezeTokenSuccess, getActiveFreeze,
    getFreezeCommission, getFreezeCommissionSuccess,
    getOmtToken,
    setIndexFreezeModalStatus
} from "../../store/cabinet/action";
import {useDispatch, useSelector} from "react-redux";
import {floorToDecimals} from "../../helpers/floorHandler";
import backIcon from "../../theme/assets/svg/omt_btn_back_arrow.svg";
import {toast} from "react-toastify";


const IndexFreezeModal = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const omtToken = useSelector(store => store.cabinet.omtToken);
    const indexFreezeModalStatus = useSelector(store => store.cabinet.indexFreezeModalStatus);
    const freezeTokenStatus = useSelector(store => store.cabinet.freezeTokenStatus);
    const freezeTokenStatusStarted = useSelector(store => store.cabinet.freezeTokenStatusStarted);
    const freezeCommission = useSelector(store => store.cabinet.freezeCommission);
    const freezeCommissionStarted = useSelector(store => store.cabinet.freezeCommissionStarted);


    const [sum, setSum] = useState('');
    const [period, setPeriod] = useState(omtToken?.staking?.days[0] ? omtToken?.staking?.days[0] / 30 : 0);
    const [profit, setProfit] = useState(0);
    const [reInvest, setReInvest] = useState(false);
    const [formStep, setFormStep] = useState('1');

    const getAvailableAmount = () => {
        const tempAmount = Math.floor(omtToken?.wallet?.balance * 100);
        return Number(Number(tempAmount / 100).toFixed(2));
    }

    const isFreezeSuccess = () => {
        if (freezeCommission){
            return Number(sum) >= omtToken?.staking?.min_invest_amount * 1 && Number(sum) <= getAvailableAmount() && freezeCommission.commission <= freezeCommission.wallet.amount
        } else return false
    }

    const mainBtnHandler = () =>{
        if (!freezeTokenStatusStarted){
            dispatch(freezeToken(omtToken?.staking?.id, {amount: sum, days: period * 30, auto_reinvest: reInvest}))
        }
    }

    const clearForm = () =>{
        setFormStep('1');
        setSum('');
        setPeriod(omtToken?.staking?.days[0] ? omtToken?.staking?.days[0] / 30 : 0);
        setProfit(0);
        setReInvest(false);
        dispatch(getFreezeCommissionSuccess(false));
    }

    const closeModal = () => {
        dispatch(setIndexFreezeModalStatus(false))
        clearForm();
    }

    useEffect(()=>{
        if (Number(sum) > 0 && omtToken){
            dispatch(getFreezeCommission(omtToken?.wallet?.currency_id, sum))
        }
    },[sum, omtToken, dispatch])

    useEffect(() => {
        if (omtToken && period && sum > 0) {
            let percentProfit = Number(sum);
            let step;
            let dayApy = (omtToken?.staking?.percents[period * 30] * 1) / 365;
            let totalProfit = 0;

            for (step = 0; step < period * 30; step++) {
                let profit = ((percentProfit / 100) * (dayApy));
                if (reInvest){
                    percentProfit += profit
                } else {
                    totalProfit += profit
                }
            }
            setProfit(floorToDecimals(Number(percentProfit + totalProfit), 4))
        }
    }, [reInvest, period, sum, omtToken])

    useEffect(() => {
        if (indexFreezeModalStatus) {
            dispatch(getOmtToken());
        }
    }, [indexFreezeModalStatus, dispatch]);

    useEffect(() => {
        if (omtToken) {
            setPeriod(omtToken?.staking?.days[0] ? omtToken?.staking?.days[0] / 30 : 0)
        }
    }, [omtToken])

    useEffect(()=>{
        if (freezeTokenStatus){
            closeModal();
            toast(t('Ваши токены успешно заморожены!'));
            dispatch(clearIndexList(true));
            dispatch(getActiveFreeze(1));
            dispatch(freezeTokenSuccess(false));
        }
    },[freezeTokenStatus, dispatch]) // eslint-disable-line

    return (
        <Popup
            open={indexFreezeModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={() => {
                    closeModal()
                }}>
                    <div/>
                    <div/>
                </div>
                {formStep === '1' ?
                    <div className={`${Styles.modalContentWrap}`}>
                        <div className={`${Styles.title}`}>{t('Заморозить')}</div>
                        {omtToken ?
                            <div className={`${Styles.modalContentBox}`}>
                                <div className={`${Styles.inputWrap}`}>
                                    <div
                                        className={`${Styles.inputTitle}`}>{t('Введите кол-во токенов, которые хотите заморозить')}</div>
                                    <input
                                        type="number"
                                        placeholder={`${t('От')} ${omtToken?.staking?.min_invest_amount * 1} OMT20`}
                                        className={`${Styles.cabinetInput}`}
                                        onChange={(e) => {
                                            if (e.target.value < 0) {
                                                setSum(0)
                                            } else {
                                                setSum(e.target.value)
                                            }
                                        }}
                                        value={sum}
                                    />
                                    <div className={`${Styles.balance}`}>{t('Доступный баланс')}: <span className={Styles.colorOrange}>{getAvailableAmount()} OMT20</span></div>
                                    <div className={`${Styles.inputNote}`}>{t('Коммиссия')}: <span>{freezeCommission ? `${freezeCommission.commission} ${freezeCommission.currency.short_name}` : freezeCommissionStarted ? t('Загрузка...') : t('Введите сумму')}</span> {freezeCommission ? freezeCommission.commission <= freezeCommission.wallet.amount ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span> : null}</div>
                                </div>
                                <div className={`${Styles.sliderWrap}`}>
                                    <div
                                        className={`${Styles.sliderTitle}`}>{t('Выберите срок заморозки ваших токенов')}:
                                    </div>
                                    <div className={`${Styles.sliderTitleValues}`}>
                                        <div className={`${Styles.sliderTitleValuesItem}`}>{period} {t('мес.')}</div>
                                        <div
                                            className={`${Styles.sliderTitleValuesItem}`}>{omtToken?.staking?.days[omtToken?.staking?.days.length - 1] / 30} {t('мес.')}</div>
                                    </div>
                                    <div className={`${Styles.sliderBox}`}>
                                        <ReactSlider
                                            className={Styles.customSlider} // Используем объект Styles для классов
                                            thumbClassName={Styles.customThumb}
                                            trackClassName={Styles.customTrack}
                                            min={omtToken?.staking?.days[0] / 30}
                                            max={omtToken?.staking?.days[omtToken?.staking?.days.length - 1] / 30}
                                            value={period}
                                            onChange={(val) => setPeriod(val)}
                                            renderTrack={(props, state) => (
                                                <div
                                                    key={state.index}
                                                    className={`${Styles.customTrack} ${state.index === 0 ? Styles.filledTrack : Styles.unfilledTrack}`}
                                                    style={{
                                                        ...props.style,
                                                        backgroundColor: state.index === 0 ? '#FF0' : '#5b5806',
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className={`${Styles.profitWrap}`}>
                                    <div className={`${Styles.profitItem}`}>
                                        <div className={`${Styles.profitItemName}`}>{t('Ожидаемая годовая ставка')}:</div>
                                        <div className={`${Styles.profitItemValue}`}>≈ {omtToken?.staking?.percents ? omtToken?.staking?.percents[period * 30] * 1 : '0'}%</div>
                                    </div>
                                    <div className={`${Styles.profitItem}`}>
                                        <div className={`${Styles.profitItemName}`}>{t('Ожидаемая прибыль')}:</div>
                                        <div className={`${Styles.profitItemValue}`}>{sum > 0 ? `≈ ${profit} OMT20` : t('Введите сумму')}</div>
                                    </div>
                                </div>
                                <div className={`${Styles.reinvestWrap}`}>
                                    <div className={`${Styles.reinvestTitle}`}>{t('Авто-реинвест')}</div>
                                    <div className={`${Styles.reinvestBox}`}>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => {
                                                setReInvest(e.target.checked)
                                            }}
                                            checked={reInvest}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={`${Styles.button} ${isFreezeSuccess() ? null : Styles.buttonDisabled}`}
                                    onClick={()=>{
                                        if (isFreezeSuccess()){
                                            setFormStep('2');
                                        }
                                    }}
                                >
                                    {t('Заморозить')}
                                </div>
                            </div>
                            : null}
                    </div>
                    : null}
                {formStep === '2' ?
                    <div className={`${Styles.modalContentWrap}`}>
                        <div className={`${Styles.titleRow}`}>
                            <div className={`${Styles.backBtn}`} onClick={()=>{setFormStep('1')}}>
                                <img src={backIcon} alt="back arrow"/>
                                <span>{t('Назад')}</span>
                            </div>
                            <div className={`${Styles.title}`}>{t('Подтвердите заморозку')}</div>
                        </div>

                        <div className={`${Styles.modalConfirmWrap}`}>
                            <div className={`${Styles.modalConfirmWrapItem}`}>
                                <div className={`${Styles.modalConfirmWrapItemName}`}>{t('Количество токенов')}:</div>
                                <div className={`${Styles.modalConfirmWrapItemValue}`}>{sum} OMT20</div>
                            </div>
                            <div className={`${Styles.modalConfirmWrapItem}`}>
                                <div className={`${Styles.modalConfirmWrapItemName}`}>{t('Количество месяцев')}:</div>
                                <div className={`${Styles.modalConfirmWrapItemValue}`}>{period} {t('мес.')}</div>
                            </div>
                            <div className={`${Styles.modalConfirmWrapItem}`}>
                                <div className={`${Styles.modalConfirmWrapItemName}`}>{t('Ожидаемая годовая ставка')}:</div>
                                <div className={`${Styles.modalConfirmWrapItemValue}`}>≈ {omtToken?.staking?.percents ? omtToken?.staking?.percents[period * 30] * 1 : '0'}%</div>
                            </div>
                            <div className={`${Styles.modalConfirmWrapItem}`}>
                                <div className={`${Styles.modalConfirmWrapItemName}`}>{t('Ожидаемая прибыль')}:</div>
                                <div className={`${Styles.modalConfirmWrapItemValue}`}>{sum > 0 ? `≈ ${profit} OMT20` : t('Введите сумму')}</div>
                            </div>
                            <div className={`${Styles.modalConfirmWrapItem}`}>
                                <div className={`${Styles.modalConfirmWrapItemName}`}>{t('Авто-реинвест')}:</div>
                                <div className={`${Styles.modalConfirmWrapItemValue} ${reInvest ? null : Styles.modalConfirmWrapItemValueRed}`}>{reInvest ? t('ВКЛЮЧЕН') : t('ВЫКЛЮЧЕН')}</div>
                            </div>
                        </div>
                        <div
                            className={`${Styles.button}`}
                            onClick={() => {mainBtnHandler()}}
                        >
                            {t('Подтвердить')}
                        </div>
                    </div>
                    : null}
            </div>
        </Popup>
    );
};

export default IndexFreezeModal;
