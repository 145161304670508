import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHArelativeAverageConditions = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Relative-average conditions')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Relative-average conditions</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Relative-average conditions for trade entry threshold.</div>
                                <div className={`${Styles.paragraph}`}>
                                    This means that the conditions for entering a trade are based on a
                                    "typical" or average threshold, which is determined in relation to
                                    other trades or historical data.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Min, avg, max.</div>
                                <div className={`${Styles.paragraph}`}>
                                    These indicators relate to the minimum, average, and maximum
                                    thresholds or conditions for entering a trade. In this context, these
                                    percentages represent the potential profit that a trader expects to
                                    achieve.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Approximately: 2%-5%-8% potential profit per trade.</div>
                                <div className={`${Styles.paragraph}`}>
                                    These are the specific values for these thresholds.
                                    "Approximately" indicates that these are estimated or indicative
                                    values.
                                </div>
                                <div className={`${Styles.paragraph}`}>
                                    If a trader is considering a trade and expects the potential profit to
                                    be less than 2%, they won't enter the trade, as it's below the
                                    minimum threshold. If the expected profit is 4%, it's considered an
                                    average value, and if the profit is estimated at 8% or higher, it
                                    matches the maximum threshold.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    These thresholds are set by traders to determine the cost of risk and decide on
                                    trade entry based on potential profitability in comparison with potential risks.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHArelativeAverageConditions;