import React from "react";
import {LiveChatWidget} from '@livechat/widget-react'



const ExternalChat = () => {

    function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
        console.log('LiveChatWidget.onNewEvent', event)
    }


    return (
        <LiveChatWidget
            license="15693612"
            visibility="minimized"
            onNewEvent={handleNewEvent}
        />
    );
};


export default ExternalChat;

