import React from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";



const IndexCounter = () => {
    const { t } = useTranslation();

    const indexMainData = useSelector(store => store.cabinet.indexMainData);

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.contentWrap}`}>
                    {t('Предложение ограничено по времени')}: {indexMainData?.offer?.left ? indexMainData?.offer?.left : '0'}/{indexMainData?.offer?.total ? indexMainData?.offer?.total : '0'}
                </div>
            </div>
        </section>
    );
};

export default IndexCounter;
