import React from "react";
import Styles from './styles.module.scss';
import {Puff} from "react-loader-spinner";



const Loader = (props) => {
    const {status} = props;
    if (status){
        return (
            <div className={`${Styles.loaderWrap}`}>
                <Puff
                    height="40"
                    width="40"
                    radius={1}
                    color="#F7931A"
                    ariaLabel="puff-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        )
    } else {
        return null
    }
};

export default Loader;
