import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {buySubscribe, getSubscribeCommission, setBotSubscribeModalStatus} from "../../store/cabinet/action";

import subCheckIcon from '../../theme/assets/svg/omt_subscribe_check.svg';
import subCheckProIcon from '../../theme/assets/svg/omt_subscribe_check_pro.svg';
import subCheckProPlusIcon from '../../theme/assets/svg/omt_subscribe_check_proPlus.svg';
import subCheckRegularIcon from '../../theme/assets/svg/omt_subscribe_check_regular.svg';
import {useTranslation} from "react-i18next";


const BotSubscribeModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const botSubscribeModalStatus = useSelector(store => store.cabinet.botSubscribeModalStatus);
    const botSubscribeInfo = useSelector(store => store.cabinet.botSubscribeInfo);
    const buySubscribeStatusStarted = useSelector(store => store.cabinet.buySubscribeStatusStarted);
    const subscribeCommission = useSelector(store => store.cabinet.subscribeCommission);


    const [currentSubscribe, setCurrentSubscribe] = useState(0);
    const [regularAmount, setRegularAmount] = useState(0);
    const [proAmount, setProAmount] = useState(0);
    const [proPlusAmount, setProPlusAmount] = useState(0)

    const closeModal = () =>{
        dispatch(setBotSubscribeModalStatus(false))
    }

    const balanceHandler = (subId) =>{
        let curPrice = false
        botSubscribeInfo.subscriptions.forEach((item)=>{
            if (item.id === subId){
                if (item.name === 'Pro'){
                    curPrice = item.periods[proAmount].amount - botSubscribeInfo?.amount
                }
                if (item.name === 'Regular'){
                    curPrice = item.periods[regularAmount].amount - botSubscribeInfo?.amount
                }
                if (item.name === 'ProPlus'){
                    curPrice = item.periods[proPlusAmount].amount - botSubscribeInfo?.amount
                }
            }
        })

        return botSubscribeInfo?.balance?.amount * 1 >= curPrice
    }

    const subscribeSendHandler = (subId, subIndex) =>{
        if (balanceHandler(subId) && !buySubscribeStatusStarted && isCommissionSuccess()){
            if (botSubscribeInfo.subscriptions[subIndex].name === 'Pro'){
                dispatch(buySubscribe({subscription_id: subId, period_id: botSubscribeInfo.subscriptions[subIndex].periods[proAmount].id}));
            }
            if (botSubscribeInfo.subscriptions[subIndex].name === 'Regular'){
                dispatch(buySubscribe({subscription_id: subId, period_id: botSubscribeInfo.subscriptions[subIndex].periods[regularAmount].id}));
            }
            if (botSubscribeInfo.subscriptions[subIndex].name === 'ProPlus'){
                dispatch(buySubscribe({subscription_id: subId, period_id: botSubscribeInfo.subscriptions[subIndex].periods[proPlusAmount].id}));
            }
        }
    }

    const isCommissionSuccess = () =>{
        return subscribeCommission ? Number(subscribeCommission?.wallet?.amount) >= Number(subscribeCommission?.commission) : false
    }
    
    useEffect(()=>{
        if (botSubscribeInfo?.subscriptions){
            dispatch(getSubscribeCommission(botSubscribeInfo?.subscriptions[currentSubscribe].periods[currentSubscribe === 0 ? regularAmount : currentSubscribe === 1 ? proAmount : proPlusAmount].amount * 1));
        }
    },[botSubscribeInfo, currentSubscribe, dispatch, proAmount, proPlusAmount, regularAmount])

    return (
        <Popup
            open={botSubscribeModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap} ${botSubscribeInfo?.subscriptions?.length < 2 ? Styles.modalContentWrapShort : null}`}>
                    {!botSubscribeInfo?.subscriptions?.length > 0 ?
                        <div className={`${Styles.textWrap}`}>
                            <div className={`${Styles.textWrapItem}`}>{t('Ваш запрос на покупку подписки успешно отправлен!')}</div>
                            <div className={`${Styles.textWrapItem}`}>{t('Чтобы начать использовать все возможности нашего бота, дождитесь завершения транзакции.')}</div>
                        </div> : null
                    }
                    <div className={`${Styles.titleRow}`}>
                        {botSubscribeInfo?.subscriptions?.length > 0 ? botSubscribeInfo.subscriptions.map((item, index)=>{
                            return (
                                <div
                                    className={`${Styles.titleRowItem} ${currentSubscribe === index ? Styles.titleRowItemActive : null} ${item.name === 'Regular' ? Styles.bgReg : null} ${item.name === 'Pro' ? Styles.bgPro : null} ${item.name === 'ProPlus' ? Styles.bgProPlus : null}`}
                                    key={'titleRowItem' + index}
                                    onClick={()=>{setCurrentSubscribe(index)}}
                                >
                                    {item.name === 'ProPlus' ? 'Pro+' : item.name}
                                </div>
                            )
                        }) : null}
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {botSubscribeInfo?.subscriptions?.length > 0 ? botSubscribeInfo.subscriptions.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem} ${currentSubscribe === index ? Styles.contentBoxItemActive : null} ${index === 2 ? Styles.contentBoxItemCentral : null} ${item.name === 'Pro' ? Styles.contentBoxItemPro : item.name === 'ProPlus' ? Styles.contentBoxItemProPlus : null}`} key={'contentBoxItem' + index}>
                                    <div className={`${Styles.contentBoxItemName} ${Styles.titleWSF} ${item.name === 'Pro' ? Styles.colorOrange : item.name === 'ProPlus' ? Styles.colorYellow : null}`}>{item.name === 'ProPlus' ? <span className={`${Styles.titleWSF} ${item.name === 'Pro' ? Styles.colorOrange : item.name === 'ProPlus' ? Styles.colorYellow : null}`}>Pro<span className={`${item.name === 'Pro' ? Styles.colorOrange : item.name === 'ProPlus' ? Styles.colorYellow : null}`}>+</span> </span> : item.name}</div>
                                    {item.name === 'Pro' || item.name === 'ProPlus' ? <div className={`${Styles.contentBoxItemAbsNote} ${Styles.titleWSF}`}>{item.name === 'Pro' ? t('Хороший выбор!') : t('Лучший выбор!')}</div> : null}
                                    <div className={`${Styles.contentBoxItemPrice} ${Styles.titleWSF}`}>
                                        {/*{t('Цена')}: {botMainData?.subscription?.subscription?.name === item.name ? item.periods[item.name === 'Pro' ? proAmount : regularAmount]?.amount * 1 : item.periods[item.name === 'Pro' ? proAmount : regularAmount]?.amount - botSubscribeInfo?.amount} OMT_BEP20*/}
                                        {t('Цена')}: <span className={`${Styles.titleWSF} ${item.name === 'Pro' ? Styles.colorOrange : item.name === 'ProPlus' ? Styles.colorYellow : null}`}>{item.periods[item.name === 'Pro' ? proAmount : item.name === 'ProPlus' ? proPlusAmount : regularAmount]?.amount * 1}</span> OMT_BEP20
                                    </div>
                                    <div className={`${Styles.contentBoxItemPeriodWrap}`}>
                                        {item?.periods?.map((itemPeriod, indexPeriod)=>{
                                            return (
                                                <div
                                                    className={`${Styles.contentBoxItemPeriodItem}`}
                                                    key={'contentBoxItemPeriodItem' + indexPeriod}
                                                    onClick={()=>{
                                                        if (item.name === 'Pro'){
                                                            setProAmount(indexPeriod);
                                                        } else if (item.name === 'ProPlus'){
                                                            setProPlusAmount(indexPeriod);
                                                        } else {
                                                            setRegularAmount(indexPeriod);
                                                        }
                                                    }}
                                                >
                                                    <div className={`${Styles.contentBoxItemPeriodItemImgWrap}`}>
                                                        <img
                                                            src={item.name === 'Pro' ?
                                                                proAmount === indexPeriod ?
                                                                    subCheckProIcon : subCheckIcon : item.name === 'ProPlus' ?
                                                                    proPlusAmount === indexPeriod ?
                                                                    subCheckProPlusIcon : subCheckIcon :
                                                                    regularAmount === indexPeriod ?
                                                                    subCheckRegularIcon : subCheckIcon
                                                            }
                                                            alt="check icon"
                                                        />
                                                    </div>
                                                    <div className={`${Styles.contentBoxItemPeriodItemValue}`}>{itemPeriod.month} {t('мес.')}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={`${Styles.contentBoxItemLink} ${item.name === 'Pro' ? Styles.colorOrange : item.name === 'ProPlus' ? Styles.colorYellow : null}`} onClick={()=>{window.open("https://honee.ai/white-paper/honee-ai", "_blank")}}>{t('Узнать больше о плане')}</div>
                                    <div className={`${Styles.btn} ${item.name === 'Regular' ? Styles.btnRegular : item.name === 'ProPlus' ? Styles.btnYellow : null} ${balanceHandler(item.id) && !buySubscribeStatusStarted && isCommissionSuccess() ? null : Styles.btnDisabled}`} onClick={()=>{subscribeSendHandler(item.id, index)}}>
                                        {buySubscribeStatusStarted ? t('Загрузка...') : t('Подписаться')}
                                    </div>
                                    <div className={`${Styles.balance}`}>{t('Доступный баланс')}: <span className={Styles.colorOrange}>{botSubscribeInfo?.balance?.amount * 1} OMT_BEP20</span></div>
                                    <div className={`${Styles.commission}`}>{t('Комиссия сети:')} <span className={Styles.colorOrange}>{subscribeCommission?.commission ? subscribeCommission?.commission * 1 : '0'} BNB</span> {subscribeCommission?.wallet?.amount || Number(subscribeCommission?.wallet?.amount) === 0 ? isCommissionSuccess() ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span> : null}</div>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default BotSubscribeModal;
