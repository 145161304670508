import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';

import omtcImg from '../../../theme/assets/svg/omt_whitepaper_omtc.svg';


const WhitePaperOEomtcStaking = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('OMT Staking')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>OMT Staking</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    21% annual return<br/>
                                    Each subsequent year reduced by 1.9% until reaching 5.5% annual return
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={omtcImg} alt="OMT Staking"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperOEomtcStaking;