import React from "react";
import Styles from './styles.module.scss';
import {useDispatch, useSelector} from "react-redux";
import { PopupButton } from '@typeform/embed-react'
import {getUserData} from "../../store/main/action";
import {useTranslation} from "react-i18next";




const AmbassadorsRequest = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const ambassadorTurnover = useSelector(store => store.cabinet.ambassadorTurnover);
    const userData = useSelector(store => store.main.userData);


    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody} ${ambassadorTurnover?.referral >= 5000 ?  Styles.contentWrapMBC : null}`}>
                <div className={`${Styles.title}`}>{t('Зарегистрируйтесь в нашей программе амбассадоров')}:</div>
                <div className={`${Styles.note}`}>
                    {t('Здравствуйте, потенциальные амбаассадоры! Мы рады вашему интересу ĸ нашей амбассадорсĸой программе. Если вы готовы присоединиться ĸ нам и стать частью ĸоманды, воплощающей инновации в мире ĸриптовалют, пожалуйста, отправьте свою заявĸу ниже, нажав ĸнопĸу "Отправить заявĸу". Будьте готовы ĸ интересным возможностям, эĸсĸлюзивным привилегиям и получить шанс определять наше развитие. Мы будем рады приветствовать вас в нашей ĸоманде! Подробнее о программе')} <a className={`${Styles.ambassadorlink}`} href="https://omt.technology/news/become_an_ambassador_omt_and_enjoy_amazing_benefits__n11">{t('здесь')}</a>
                </div>
                {ambassadorTurnover?.referral >= 5000 ? null : <div className={`${Styles.turnover}`}>{t('Вы еще не достигли оборота струĸтуры в $5000. Выполните условие и отправляйте заявĸу на участие в амбассадорсĸой программе!')}</div>}
                {userData?.ambassador_status === 'pending' || userData?.ambassador_status === 'canceled' ?
                    <div className={`${Styles.btn} ${Styles.btnCs}`}>
                        {t('Спасибо! Ваша заявка находится на рассмотрении!')}
                    </div> : <>
                        {ambassadorTurnover?.referral >= 5000 ?
                            <PopupButton
                                id="NucKtsMb"
                                style={{ fontSize: 20 }}
                                className={`${Styles.btn}`}
                                onSubmit={() => {
                                    dispatch(getUserData());
                                }}
                                onClose={() => {
                                    dispatch(getUserData());
                                }}
                                hidden={{
                                    email: userData?.email,
                                }}
                            >
                                {t('Отправить заявку')}
                            </PopupButton> :
                            <div className={`${Styles.btn} ${Styles.btnWC}`}>{t('Отправить заявку')}</div>
                        }
                    </>
                }
            </div>
        </section>
    );
};

export default AmbassadorsRequest;
