import React from "react";
import Styles from './styles.module.scss'



const NextIcon = (props) => {
    const {isHover} = props;

    return (
        <section className={`${Styles.iconWrap}`}>
            <svg width="20" height="9" viewBox="0 0 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.989 5.01363C16.5784 4.61998 16.5784 3.96359 16.989 3.56994L19.8154 0.860158C20.0205 0.663509 20.0205 0.335603 19.8154 0.138954C19.6222 -0.0463182 19.3172 -0.0463179 19.124 0.138954L15.1533 3.94576C14.9565 4.13446 14.9565 4.44911 15.1533 4.63781L19.124 8.44461C19.3172 8.62989 19.6222 8.62989 19.8154 8.44461C20.0205 8.24796 20.0205 7.92006 19.8154 7.72341L16.989 5.01363ZM9.48604 5.01363C9.07544 4.61998 9.07544 3.96359 9.48604 3.56994L11.5931 1.54987C11.7982 1.35322 11.7982 1.02531 11.5931 0.828664C11.3998 0.643392 11.0949 0.643391 10.9016 0.828663L7.65035 3.94576C7.45353 4.13446 7.45353 4.44911 7.65035 4.63781L10.9016 7.7549C11.0949 7.94018 11.3998 7.94018 11.5931 7.75491C11.7982 7.55826 11.7982 7.23035 11.5931 7.0337L9.48604 5.01363ZM3.37073 1.51837C3.57584 1.71502 3.57584 2.04293 3.37073 2.23958L1.9831 3.56994C1.57251 3.96359 1.57251 4.61998 1.9831 5.01363L3.37073 6.34399C3.57584 6.54064 3.57584 6.86855 3.37073 7.0652C3.17748 7.25047 2.87253 7.25047 2.67929 7.0652L0.147411 4.63781C-0.0494122 4.44911 -0.0494121 4.13446 0.147411 3.94576L2.67929 1.51837C2.87254 1.3331 3.17748 1.3331 3.37073 1.51837Z"
                    fill={isHover ? "#F7931A" : "#58C1EF"}
                />
            </svg>
        </section>
    );
};

export default NextIcon;
