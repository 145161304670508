import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHAmoneyFlowIndex = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Money Flow index (MFI)')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Money Flow index (MFI)</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The Money Flow Index (MFI) is a technical <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/o/oscillator.asp", "_blank")}}>oscillator</span> that uses price and volume
                                    data for identifying <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/o/overbought.asp", "_blank")}}>overbought</span> or <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/o/oversold.asp", "_blank")}}>oversold</span> signals in an asset. It can also be
                                    used to spot <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/d/divergence.asp", "_blank")}}>divergences</span> which warn of a trend change in price. The oscillator
                                    moves between 0 and 100.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Unlike conventional oscillators such as the <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/r/rsi.asp", "_blank")}}>Relative Strength Index</span> (RSI), the
                                    Money Flow Index incorporates both price and <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/v/volume.asp", "_blank")}}>volume</span> data, as opposed to just
                                    price. For this reason, some analysts call MFI the volume-weighted RSI.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>The Formulas for the Money Flow Index Are:</div>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.colorOrange}>Money Flow Index</span> = 100 - (100 / 1 + Money Flow Ratio)<br/>
                                    where: ( / = dividing, * = multiplying )<br/>
                                    <span className={Styles.colorOrange}>Money Flow Ratio</span> = 14 Period Positive Money Flow / 14 Period Negative Money Flow<br/>
                                    <span className={Styles.colorOrange}>Raw Money Flow</span> = Typical Price * Volume<br/>
                                    <span className={Styles.colorOrange}>Typical Price</span> = (High + Low + Close) / 3<br/>
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    When the price advances from one period to the next Raw Money Flow is
                                    positive and it is added to Positive Money Flow. When Raw Money Flow is
                                    negative because the price dropped that period, it is added to Negative Money
                                    Flow.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>What Does the Money Flow Index Tell You?</div>
                                <div className={`${Styles.paragraph}`}>
                                    One of the primary ways to use the Money Flow Index is when there is
                                    a <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/a/atthemoney.asp", "_blank")}}>divergence</span>. A divergence is when the oscillator is moving in the opposite
                                    direction of price. This is a signal of a potential reversal in the prevailing price
                                    trend.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    For example, a very high Money Flow Index that begins to fall below a reading
                                    of 80 while the underlying security continues to climb is a price reversal signal
                                    to the downside. Conversely, a very low MFI reading that climbs above a
                                    reading of 20 while the underlying security continues to sell off is a price
                                    reversal signal to the upside.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Traders also watch for larger divergences using multiple <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/e/elliottwavetheory.asp", "_blank")}}>waves</span> in the price and
                                    MFI. For example, a stock peaks at $10, pulls back to $8, and then rallies to
                                    $12. The price has made two successive highs, at $10 and $12. If MFI makes a
                                    lower higher when the price reaches $12, the indicator is not confirming the
                                    new high. This could foreshadow a decline in price.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The overbought and oversold levels are also used to signal possible trading
                                    opportunities. Moves below 10 and above 90 are rare. Traders watch for the
                                    MFI to move back above 10 to signal a <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/l/long.asp", "_blank")}}>long</span> trade, and to drop below 90 to
                                    signal a <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/s/shortselling.asp", "_blank")}}>short</span> trade.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    Other moves out of overbought or oversold territory can also be useful. For
                                    example, when an asset is in an <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/u/uptrend.asp", "_blank")}}>uptrend</span>, a drop below 20 (or even 30) and then
                                    a rally back above it could indicate a pullback is over and the price uptrend is
                                    resuming. The same goes for a downtrend. A short-term rally could push the
                                    MFI up to 70 or 80, but when it drops back below that could be the time to
                                    enter a short trade in preparation for another drop.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHAmoneyFlowIndex;