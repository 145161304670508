import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
// import {useDispatch, useSelector} from "react-redux";
// import CryptoIcon from "../_UI/cryptoIcon";

// import changeBtnIcon from '../../theme/assets/svg/omt_index_change_btn.svg';
// import {spaceHandler} from "../../helpers/dotsHandler";
// import {getIndexMainData, indexSwap, indexSwapSuccess} from "../../store/cabinet/action";
// import {floorToDecimals} from "../../helpers/floorHandler";
// import changeBtnIcon from '../../theme/assets/svg/omt_index_change_btn.svg';
// import {spaceHandler} from "../../helpers/dotsHandler";
// import {getIndexMainData, indexSwap, indexSwapSuccess} from "../../store/cabinet/action";
import {useNavigate} from "react-router-dom";



const IndexSwap = () => {
    const { t } = useTranslation();
    // const dispatch = useDispatch();
    //
    // const indexMainData = useSelector(store => store.cabinet.indexMainData);
    // const indexSwapStatus = useSelector(store => store.cabinet.indexSwapStatus);
    // const indexSwapStatusStarted = useSelector(store => store.cabinet.indexSwapStatusStarted);
    

    // const [currentSwapType, setCurrentSwapType] = useState(false);
    // const [currentBalance, setCurrentBalance] = useState({balance: "0", balanceUsdt: "0"})
    //
    // const [honeeAmount, setHoneeAmount] = useState('');
    // const [omt20Amount, setOmt20Amount] = useState('');


    const swapBlurHandler = () =>{
        return false
    }


    // const isSwapButtonAvailable = () =>{
    //     if (honeeAmount !== '' && omt20Amount !== '' && honeeAmount && omt20Amount && !indexSwapStatusStarted && !swapBlurHandler()){
    //         return Number(currentBalance.balance * 1) >= (currentSwapType ? Number(omt20Amount) : Number(honeeAmount));
    //     } else {
    //         return false
    //     }
    // }
    // const dispatch = useDispatch();
    const navigate = useNavigate();

    // const indexMainData = useSelector(store => store.cabinet.indexMainData);
    // const indexSwapStatus = useSelector(store => store.cabinet.indexSwapStatus);
    // const indexSwapStatusStarted = useSelector(store => store.cabinet.indexSwapStatusStarted);
    //
    //
    // const [currentSwapType, setCurrentSwapType] = useState(false);
    // const [currentBalance, setCurrentBalance] = useState({balance: "0", balanceUsdt: "0"})
    //
    // const [honeeAmount, setHoneeAmount] = useState('');
    // const [omt20Amount, setOmt20Amount] = useState('');



    // const isSwapButtonAvailable = () =>{
    //     if (honeeAmount !== '' && omt20Amount !== '' && honeeAmount && omt20Amount && !indexSwapStatusStarted){
    //         return Number(currentBalance.balance * 1) >= (currentSwapType ? Number(omt20Amount) : Number(honeeAmount));
    //     } else {
    //         return false
    //     }
    // }

    // const inputContentHandler = (isUp, pos) =>{
    //
    //     const omt20Input =
    //         <div className={`${Styles.inputWrap}`}>
    //             <div className={`${Styles.inputTitle}`}>{pos === 'top' ? t('Монета которую хотите обменять') : t('На')}</div>
    //             <div className={`${Styles.inputBox} ${Styles.inputBoxOmt}`}>
    //                 <input
    //                     type="number"
    //                     onChange={(e)=>{
    //                         if (e.target.value < 0 || e.target.value === ''){
    //                             setOmt20Amount('');
    //                             setHoneeAmount('');
    //                         } else {
    //                             setOmt20Amount(e.target.value)
    //                             setHoneeAmount(e.target.value * indexMainData?.index?.rate)
    //                         }
    //                     }}
    //                     value={omt20Amount}
    //                 />
    //                 <div className={`${Styles.balance}`}>{t('Доступный баланс')}: <span className={`${Styles.colorOrange}`}>{indexMainData?.indexOwned?.balance ? indexMainData?.indexOwned?.balance * 1 : '0'}</span> OMT 20</div>
    //                 <div className={`${Styles.absCur}`}>OMT 20</div>
    //                 <div className={`${Styles.absIcon}`}>
    //                     <CryptoIcon short_name="OMT20"/>
    //                 </div>
    //             </div>
    //         </div>
    //
    //
    //     const honeeInput =
    //         <div className={`${Styles.inputWrap}`}>
    //             <div className={`${Styles.inputTitle}`}>{pos === 'top' ? t('Монета которую хотите обменять') : t('На')}</div>
    //             <div className={`${Styles.inputBox}`}>
    //                 <input
    //                     type="number"
    //                     onChange={(e)=>{
    //                         if (e.target.value < 0 || e.target.value === ''){
    //                             setHoneeAmount('')
    //                             setOmt20Amount('');
    //                         } else {
    //                             setHoneeAmount(e.target.value);
    //                             setOmt20Amount(e.target.value / indexMainData?.index?.rate)
    //                         }
    //                     }}
    //                     value={honeeAmount}
    //                 />
    //                 <div className={`${Styles.balance}`}>{t('Доступный баланс')}: <span className={`${Styles.colorOrange}`}>{indexMainData?.honeeWallet?.amount ? indexMainData?.honeeWallet?.amount * 1 : '0'}</span> Honee</div>
    //                 <div className={`${Styles.absCur}`}>Honee</div>
    //                 <div className={`${Styles.absIcon}`}>
    //                     <CryptoIcon short_name="HONEE"/>
    //                 </div>
    //             </div>
    //         </div>
    //
    //
    //     return isUp ? honeeInput : omt20Input
    // }

    const swapBtnHandler = () =>{
        navigate('/swap')
        // if (isSwapButtonAvailable()){
        //     dispatch(indexSwap({direction: currentSwapType ? 'sell' : 'buy', amount_sell: currentSwapType ? omt20Amount : honeeAmount}))
        // }
    }

    // useEffect(()=>{
    //     if (indexMainData){
    //         currentSwapType ?
    //             setCurrentBalance({balance: indexMainData?.indexOwned?.balance, balanceUsdt: indexMainData?.indexOwned?.value}) :
    //             setCurrentBalance({balance: indexMainData?.honeeWallet?.amount, balanceUsdt: indexMainData?.honeeWallet?.amount_usdt})
    //     }
    // },[currentSwapType, indexMainData])
    //
    // useEffect(()=>{
    //     if (indexSwapStatus){
    //         dispatch(getIndexMainData());
    //         dispatch(indexSwapSuccess(false));
    //         setHoneeAmount('');
    //         setOmt20Amount('');
    //     }
    // },[dispatch, indexSwapStatus])
    
    
    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            {swapBlurHandler() ?
                <div className={`${Styles.blurAbs}`}>
                    <div className={`${Styles.blurMessage}`}>{t('Скоро будет доступно')}</div>
                </div> : null
            }
            <div className={`${Styles.honeeCardBody} ${swapBlurHandler() ? Styles.honeeCardBodyWB : null}`}>
                <div className={`${Styles.contentWrap}`}>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.titleWSF}`}><span className={`${Styles.colorOrange} ${Styles.titleWSF}`}>P2P</span> SWAP <span className={`${Styles.betaType} ${Styles.titleWSF}`}>BETA</span></div>
                        {/*<div className={`${Styles.balanceWrap}`}>*/}
                        {/*    <div className={`${Styles.balanceWrapItem}`}>*/}
                        {/*        <div className={`${Styles.balanceWrapItemName} ${Styles.titleWSF}`}>{t('Баланс')}:</div>*/}
                        {/*        <div className={`${Styles.balanceWrapItemValue}`}>{(Number(currentBalance.balance).toFixed(2) * 1)}</div>*/}
                        {/*    </div>*/}
                        {/*    <div className={`${Styles.balanceWrapItem}`}>*/}
                        {/*        <div className={`${Styles.balanceWrapItemName} ${Styles.titleWSF}`}>{t('Ценность')}:</div>*/}
                        {/*        <div className={`${Styles.balanceWrapItemValue}`}>${(Number(currentBalance.balanceUsdt).toFixed(2) * 1)}</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className={`${Styles.textWrap}`}>
                        <div className={`${Styles.textP}`}>
                            <span>P2P SWAP</span> - {t('это уникальное решение, которое сочетает P2P-торговлю и традиционные DEX, интегрированные прямо в криптокошелек ОМТ. С помощью этого продукта пользователи могут без труда покупать и продавать криптоиндексы внутри нашей платформы')}.
                        </div>
                        <div className={`${Styles.textP}`}>
                            {t('Воспользуйтесь нашим P2P SWAP (beta) для того чтобы купить или продать INDEX OMT20')}.
                        </div>
                    </div>
                    <div className={`${Styles.swapWrap}`}>
                        {/*{inputContentHandler(!currentSwapType, 'top')}*/}
                        {/*<div className={`${Styles.changeBtn}`} onClick={()=>{setCurrentSwapType(!currentSwapType)}}>*/}
                        {/*    <img src={changeBtnIcon} alt="change to swap"/>*/}
                        {/*</div>*/}
                        {/*{inputContentHandler(currentSwapType, 'bottom')}*/}
                        {/*<div className={`${Styles.rateRow}`}>*/}
                        {/*    <div className={`${Styles.rateRowName}`}>{t('Цена')}</div>*/}
                        {/*    <div className={`${Styles.rateRowValue}`}>1 <span>OMT20</span> = {spaceHandler(indexMainData?.index?.rate * 1, true)} Honee</div>*/}
                        {/*</div>*/}
                        <div className={`${Styles.btn}`} onClick={() => {swapBtnHandler()}}>P2P SWAP (beta)</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IndexSwap;
