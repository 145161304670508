import React, {useState} from "react";
import Styles from './styles.module.scss'
import NavIcon from "../_UI/navIcon";
import { useTranslation } from "react-i18next";

import log_out_icon from '../../theme/assets/svg/honee_nav_logout_icon.svg';
import {useDispatch, useSelector} from "react-redux";
import {setMobileNavStatus} from "../../store/cabinet/action";
import {useNavigate} from "react-router-dom";
import LangSwitcher from "../langSwitcher";
// import {getTotalBalance} from "../../store/wallet/action";
import Logo from "../_UI/logo";
// import instIcon from "../../theme/assets/svg/honee_main_soc_inst.svg";
// import twIcon from "../../theme/assets/svg/honee_main_soc_tw.svg";
import telIcon from "../../theme/assets/svg/honee_main_soc_tel.svg";
import ytIcon from "../../theme/assets/svg/honee_main_soc_yt.svg";
import disIcon from "../../theme/assets/svg/honee_main_soc_dis.svg";
import gitIcon from "../../theme/assets/svg/h2_git_icon.svg";
import {logout} from "../../store/main/action";
import {floorToDecimals} from "../../helpers/floorHandler";
// import ConnectButton from "../_UI/connectBtn";


const CabinetNavigation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    // const { open, close } = useWeb3Modal()

    const userData = useSelector(store => store.main.userData);
    const mobileNavStatus = useSelector(store => store.cabinet.mobileNavStatus);

    const [isHover, setIsHover] = useState('');

    const pageHandler = (page) =>{
        return window.location.pathname.indexOf(page) === 1
    }



    return (
        <section className={`${Styles.container} ${mobileNavStatus ? Styles.containerActive : null}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.logoWrap}`} onClick={()=>{navigate('/')}}>
                    <Logo/>
                </div>
                <div className={`${Styles.balanceWrap}`}>
                    {userData.wallets_amount ? floorToDecimals(userData.wallets_amount, 2) * 1 : '0'} USDT
                </div>
                <div className={`${Styles.mainNavWrap}`}>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('honee') === 1 ? Styles.navItemActive : null}`}
                        onClick={() => {
                            navigate('/honee');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={() => {
                            setIsHover('3')
                        }}
                        onMouseLeave={() => {
                            setIsHover('')
                        }}
                    >
                        <NavIcon type={'3'} isHover={pageHandler('honee') ? true : isHover === '3'}/>
                        <span>HoneeAI 2.0</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('swap') === 1 ? Styles.navItemActive : null}`}
                        onClick={() => {
                            navigate('/swap');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={() => {
                            setIsHover('17')
                        }}
                        onMouseLeave={() => {
                            setIsHover('')
                        }}
                    >
                        <NavIcon type={'17'} isHover={pageHandler('swap') ? true : isHover === '17'}/>
                        <span>P2P Swap <span className={`${Styles.titleWSF}`}>BETA</span></span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${pageHandler('wallet') ? Styles.navItemActive : null}`}
                        onClick={() => {
                            navigate('/wallet');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={() => {
                            setIsHover('12')
                        }}
                        onMouseLeave={() => {
                            setIsHover('')
                        }}
                    >
                        <NavIcon type={'12'} isHover={pageHandler('wallet') ? true : isHover === '12'}/>
                        <span>{t('Кошелек')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('staking') === 1 ? Styles.navItemActive : null}`}
                        onClick={() => {
                            navigate('/staking');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={() => {
                            setIsHover('2')
                        }}
                        onMouseLeave={() => {
                            setIsHover('')
                        }}
                    >
                        <NavIcon type={'2'} isHover={pageHandler('staking') ? true : isHover === '2'}/>
                        <span>{t('Стейкинг')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('exchange') === 1 ? Styles.navItemActive : null}`}
                        onClick={() => {
                            navigate('/exchange');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={() => {
                            setIsHover('8')
                        }}
                        onMouseLeave={() => {
                            setIsHover('')
                        }}
                    >
                        <NavIcon type={'8'} isHover={pageHandler('exchange') ? true : isHover === '8'}/>
                        <span>{t('Обмен')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${Styles.navItemIndex} ${window.location.pathname.indexOf('index') === 1 ? Styles.navItemActive : null}`}
                        onClick={() => {
                            navigate('/index');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={() => {
                            setIsHover('16')
                        }}
                        onMouseLeave={() => {
                            setIsHover('')
                        }}
                    >
                        <NavIcon type={'16'} isHover={pageHandler('index') ? true : isHover === '16'}/>
                        <span>Index <span>OMT20</span></span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('bonuses') === 1 ? Styles.navItemActive : null}`}
                        onClick={() => {
                            navigate('/bonuses');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={() => {
                            setIsHover('4')
                        }}
                        onMouseLeave={() => {
                            setIsHover('')
                        }}
                    >
                        <NavIcon type={'4'} isHover={pageHandler('bonuses') ? true : isHover === '4'}/>
                        <span>{t('Программа Лояльности')}</span>
                    </div>
                    {userData?.honeechize_permission || userData?.honeechize_partner ?
                        <div
                            className={`${Styles.navItem} ${Styles.navItemOrange} ${window.location.pathname.indexOf('honeechize') === 1 ? Styles.navItemActive : null}`}
                            onClick={() => {
                                navigate('/honeechize/metrics');
                                dispatch(setMobileNavStatus(false));
                            }}
                            onMouseEnter={() => {
                                setIsHover('14')
                            }}
                            onMouseLeave={() => {
                                setIsHover('')
                            }}
                        >
                            <NavIcon type={'14'} isHover={pageHandler('honeechize') ? true : isHover === '14'}/>
                            <span>Honeechize</span>
                        </div>
                        : null}
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('ambassadors') === 1 ? Styles.navItemActive : null}`}
                        onClick={() => {
                            navigate('/ambassadors');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={() => {
                            setIsHover('10')
                        }}
                        onMouseLeave={() => {
                            setIsHover('')
                        }}
                    >
                        <NavIcon type={'10'} isHover={pageHandler('ambassadors') ? true : isHover === '10'}/>
                        <span>{t('Амбассадорка')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('transactions') === 1 ? Styles.navItemActive : null}`}
                        onClick={() => {
                            navigate('/transactions');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={() => {
                            setIsHover('9')
                        }}
                        onMouseLeave={() => {
                            setIsHover('')
                        }}
                    >
                        <NavIcon type={'9'} isHover={pageHandler('transactions') ? true : isHover === '9'}/>
                        <span>{t('Транзакции')}</span>
                    </div>
                </div>
                <div className={`${Styles.otherNavWrap}`}>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('settings') === 1 ? Styles.navItemActive : null}`}
                        onClick={() => {
                            navigate('/settings');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={() => {
                            setIsHover('6')
                        }}
                        onMouseLeave={() => {
                            setIsHover('')
                        }}
                    >
                        <NavIcon type={'6'} isHover={pageHandler('settings') ? true : isHover === '6'}/>
                        <span>{t('Настройки')}</span>
                    </div>
                    <div
                        className={`${Styles.navItem} ${window.location.pathname.indexOf('support') === 1 ? Styles.navItemActive : null}`}
                        onClick={()=>{
                            navigate('/support');
                            dispatch(setMobileNavStatus(false));
                        }}
                        onMouseEnter={()=>{setIsHover('7')}}
                        onMouseLeave={()=>{setIsHover('')}}
                    >
                        <NavIcon type={'7'} isHover={pageHandler('support') ? true : isHover === '7'}/>
                        <span>{t('Поддержка')}</span>
                    </div>
                </div>
                <LangSwitcher/>
                <div className={`${Styles.socialsWrap}`}>
                    <div className={`${Styles.socialsTitle}`}>{t('Социальные сети')}</div>
                    <div className={`${Styles.socialsBox}`}>
                        <div
                            className={`${Styles.socItem}`}
                            onClick={() => {
                                window.open("https://github.com/OracleMetaTechnologies", "_blank");
                            }}
                        >
                            <img src={gitIcon} alt="GitHub"/>
                        </div>
                        {/*<div*/}
                        {/*    className={`${Styles.socItem}`}*/}
                        {/*    onClick={() => {*/}
                        {/*        window.open("https://www.instagram.com/omtwallet/", "_blank");*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <img src={instIcon} alt="Instagram"/>*/}
                        {/*</div>*/}
                        {/*<div*/}
                        {/*    className={`${Styles.socItem}`}*/}
                        {/*    onClick={() => {*/}
                        {/*        window.open("https://twitter.com/official_omtech", "_blank");*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <img src={twIcon} alt="Twitter"/>*/}
                        {/*</div>*/}
                        <div
                            className={`${Styles.socItem}`}
                            onClick={() => {
                                window.open("https://t.me/officialOMT", "_blank");
                            }}
                        >
                            <img src={telIcon} alt="Telegram"/>
                        </div>
                        <div
                            className={`${Styles.socItem}`}
                            onClick={() => {
                                window.open("https://www.youtube.com/channel/UCDx29CIHFou4lrdIDHBRmiw", "_blank");
                            }}
                        >
                            <img src={ytIcon} alt="YouTube"/>
                        </div>
                        <div
                            className={`${Styles.socItem}`}
                            onClick={() => {
                                window.open("https://discord.gg/YWMJkwkg74", "_blank");
                            }}
                        >
                            <img src={disIcon} alt="Discord"/>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.logOutBtn}`} onClick={()=>{dispatch(logout())}}>
                    <img src={log_out_icon} alt="logout"/>
                    <span>{t('Выход')}</span>
                </div>
                {/*<ConnectButton/>*/}
            </div>
        </section>
    );
};

export default CabinetNavigation;
