import React from "react";
import Styles from './styles.module.scss'



const WalletSendStep3 = () => {

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>Аутентификация безопасности</div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemTitle}`}>Код безопасности (Email)</div>
                        <input type="text"/>
                        <div className={`${Styles.contentBoxItemNote}`}>Введите код, отправленный на почту <span>eth****@gmail.com</span></div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.contentBoxItemTitle}`}>Код безопасности (Google Authenticator)</div>
                        <input type="text"/>
                    </div>
                </div>
                <div className={`${Styles.btnWrap}`}>
                    <div className={`${Styles.btnItem}`}>Отменить</div>
                    <div className={`${Styles.btnItem} ${Styles.btnItemPassive}`}>Подтвердить</div>
                </div>
            </div>
        </section>
    );
};

export default WalletSendStep3;
