import React from "react";
import Styles from './styles.module.scss';
import Logo from "../_UI/logo";

// import instIcon from '../../theme/assets/svg/honee_main_soc_inst.svg';
// import twIcon from '../../theme/assets/svg/honee_main_soc_tw.svg';
import telIcon from '../../theme/assets/svg/honee_main_soc_tel.svg';
import ytIcon from '../../theme/assets/svg/honee_main_soc_yt.svg';
import disIcon from '../../theme/assets/svg/honee_main_soc_dis.svg';
import gitIcon from '../../theme/assets/svg/h2_git_icon.svg';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";



const Footer = (props) => {
    const {withoutBg} = props;

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container} ${withoutBg ? Styles.containerWB : null}`}>
            <div className={`${Styles.borderWrap}`}>
                <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                    <Logo/>
                    <div className={`${Styles.navWrap}`}>
                        <div className={`${Styles.navItem}`} onClick={() => {navigate('/privacy-policy')}}>{t('Политика конфиденциальности')}</div>
                        <div className={`${Styles.navItem}`} onClick={() => {navigate('/user-agreement')}}>{t('Условия использования')}</div>
                    </div>
                    <div className={`${Styles.socWrap}`}>
                        <div
                            className={`${Styles.socItem}`}
                            onClick={() => {
                                window.open("https://github.com/OracleMetaTechnologies", "_blank");
                            }}
                        >
                            <img src={gitIcon} alt="GitHub"/>
                        </div>
                        {/*<div*/}
                        {/*    className={`${Styles.socItem}`}*/}
                        {/*    onClick={() => {*/}
                        {/*        window.open("https://www.instagram.com/omtwallet/", "_blank");*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <img src={instIcon} alt="Instagram"/>*/}
                        {/*</div>*/}
                        {/*<div*/}
                        {/*    className={`${Styles.socItem}`}*/}
                        {/*    onClick={() => {*/}
                        {/*        window.open("https://twitter.com/official_omtech", "_blank");*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <img src={twIcon} alt="Twitter"/>*/}
                        {/*</div>*/}
                        <div
                            className={`${Styles.socItem}`}
                            onClick={() => {
                                window.open("https://t.me/officialOMT", "_blank");
                            }}
                        >
                            <img src={telIcon} alt="Telegram"/>
                        </div>
                        <div
                            className={`${Styles.socItem}`}
                            onClick={() => {
                                window.open("https://www.youtube.com/channel/UCDx29CIHFou4lrdIDHBRmiw", "_blank");
                            }}
                        >
                            <img src={ytIcon} alt="YouTube"/>
                        </div>
                        <div
                            className={`${Styles.socItem}`}
                            onClick={() => {
                                window.open("https://discord.gg/YWMJkwkg74", "_blank");
                            }}
                        >
                            <img src={disIcon} alt="Discord"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;