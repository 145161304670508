import React from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import {setStakingWithdrawFormStatus} from "../../store/cabinet/action";


const StakingInWork = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const stakingAvailable = useSelector(store => store.cabinet.availableStaking);


    const statusHandler = (status) =>{
        switch (status){
            case 'new':
                return  t('Новый')
            case 'active':
                return t('Активный')
            case 'closed':
                return t('Завершенный')
            default:
                return t('Неизвестно')
        }
    }

    const isWithdrawSuccess = (item) =>{
        return !item.is_pending_withdraw && item?.currency?.name === 'OMT BEP20' ? Number(item.available_reward) > 0 : Number(item.profit) > 0 && item.withdraw_available
    }

    const mainBtnHandler = (item) =>{
        if (isWithdrawSuccess(item)){
            dispatch(setStakingWithdrawFormStatus({status: true, item: item, balance: false}))
        }
    }

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.title3} ${Styles.titleWSF}`}>{t('В работе')}</div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Монета')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Всего')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Дни')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>APY %</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Доходность')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Действие')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`}>
                        {stakingAvailable?.open?.length > 0 ? stakingAvailable.open.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem}`} key={'stakingAvailable' + index}>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapTitle} ${Styles.title6} ${Styles.colorGray}`}>{t('Монета')}</div>
                                        <div className={`${Styles.contentBoxItemNameWrap}`}>
                                            <div className={`${Styles.contentBoxItemNameImgWrap}`}>
                                                <CryptoIcon short_name={item.currency.short_name}/>
                                            </div>
                                            <div className={`${Styles.contentBoxItemNameBox}`}>
                                                <div className={`${Styles.contentBoxItemShortName}`}>{item.currency.short_name}</div>
                                                <div className={`${Styles.contentBoxItemFullName}`}>{item.currency.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapTitle} ${Styles.title6} ${Styles.colorGray}`}>{t('Всего')}</div>
                                        <div className={`${Styles.contentBoxItemValues}`}>{item.total * 1}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapTitle} ${Styles.title6} ${Styles.colorGray}`}>{t('Дни')}</div>
                                        <div className={`${Styles.contentBoxItemValues}`}>{item.days}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapTitle} ${Styles.title6} ${Styles.colorGray}`}>APY %</div>
                                        <div className={`${Styles.contentBoxItemValues}`}>{item.apy * 1}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapTitle} ${Styles.title6} ${Styles.colorGray}`}>{t('Доходность')}</div>
                                        <div className={`${Styles.contentBoxItemValues}`}>{item.profit * 1}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapTitle} ${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                                        <div className={`${Styles.contentBoxItemValues}`}>{statusHandler(item.status)}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapTitle} ${Styles.title6} ${Styles.colorGray}`}>{t('Действие')}</div>
                                        <div
                                            className={`${Styles.contentBoxItemValues} ${Styles.contentBoxItemValuesBtn} ${isWithdrawSuccess(item) ? null : Styles.contentBoxItemValuesDisBtn}`}
                                            onClick={()=>{mainBtnHandler(item)}}
                                        >
                                            {t('Вывод')}
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : <EmptyValues text={t('У вас пока что нет активов')} withIcon={true} withBtn={false}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default StakingInWork;
