import React, {useState, useRef, useEffect} from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import Moment from "react-moment";
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import StocksContent from "../_UI/stocksContent";

import earnedIcon from '../../theme/assets/crypto/omt_crypto_honee_icon.svg';


const CabinetBotHistory = (props) => {
    const {scrollAction} = props;
    const { t } = useTranslation();

    const scrollRef = useRef(null);

    const botHistory = useSelector(store => store.cabinet.botHistory);
    const [list, setList] = useState([]);

    const techWorkStatus = false;


    useEffect(() => {
        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                if (scrollTop + clientHeight >= scrollHeight) {
                    scrollAction(true);
                } else {
                    scrollAction(false);
                }
            }
        };

        const currentRef = scrollRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollAction]);

    // useEffect(()=>{
    //     setList([...list, ...(botHistory.data || [])] || []);
    // },[botHistory]) // eslint-disable-line

    useEffect(()=>{
        if (list?.length > 0 && botHistory?.data?.length > 0){
            let isDuplicate = false;
            list.forEach((item)=>{
                if (item.date === botHistory.data[botHistory.data.length - 1].date){
                    isDuplicate = true;
                }
            })
            if (!isDuplicate){
                setList([...list, ...(botHistory.data || [])] || []);
            }
        } else {
            setList([...list, ...(botHistory.data || [])] || []);
        }

    },[botHistory]) // eslint-disable-line

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.titleWSF}`}>{t('Биржевые')} <span className={`${Styles.titleWSF}`}>{t('Транзакции')}</span></div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Дата и время')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Пара')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Обмен')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Цена покупки')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Цена продажи')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Сумма')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Доходность')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Спред')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`} ref={scrollRef}>
                        {techWorkStatus ? <EmptyValues text={t('Ведутся технические работы')} withIcon={true} withBtn={false}/> :
                            list.length ? list.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem}`} key={'botHistory' + index}>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Дата и время')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}><Moment format="DD/MM/YYYY HH:mm">{item.date}</Moment></div></div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Пара')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>{item.pair}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Обмен')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}><StocksContent firstNameStock={item.buying_exchange} lastNameStock={item.selling_exchange} firstStock={item.buying_exchange_slug} lastStock={item.selling_exchange_slug} firstCategory={item.buying_exchange_category} lastCategory={item.selling_exchange_category}/></div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Цена покупки')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>${item.buying_price * 1 ?? 0}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Цена продажи')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>${item.selling_price * 1 ?? 0}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Сумма')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>${item.deal_amount * 1 ?? 0}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap} ${item.profit * 1 > 0 ? Styles.colorGreen : Styles.colorRed}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Доходность')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue} ${Styles.contentBoxItemWrapValueSplit}`}>
                                            <div className={`${Styles.text } ${item.profit * 1 > 0 ? Styles.colorGreen : Styles.colorRed}`}>
                                                {item.profit * 1 > 0 ? "+" : ""}{item.profit * 1 ?? 0}
                                            </div>
                                            <div className={`${Styles.imgWrap}`}>
                                                <img src={earnedIcon} alt="h2"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Спред')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>{item.spread * 1 ?? 0}%</div>
                                    </div>
                                </div>
                            )
                        }) : <EmptyValues text={t('У вас пока что нет транзакций')} withIcon={false} withBtn={false}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CabinetBotHistory;