import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHistory = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('History')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>History of Oracle Meta Technologies</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Oracle Meta Technologies is an innovative startup founded in 2016 in India by a
                                    team of developers with extensive experience in the field of crypto products.
                                    Today, Oracle is represented in several countries, including Switzerland,
                                    Germany, Austria, India, the UAE, and Hong Kong. These countries are known
                                    for their stability, security, developed legal systems, openness to innovation,
                                    and the use of efficient global solutions focused on the future.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The idea to create an AI that would address the existing market's problems
                                    came to the founders as early as 2019, and by 2020, it had its first realization.
                                    In 2021, a unique wallet with built-in AI was launched. Over the past two years,
                                    the team has grown to include several hundred people.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The founder of Oracle Meta Technologies, Manish Thakur, is a serial
                                    entrepreneur, venture investor, and business development expert. He
                                    graduated from the Faculty of Economics at Humboldt University in Berlin and
                                    has over 10 years of experience in the startup and venture investment industry.
                                    In just 1.5 years, he has created several businesses with turnovers in the tens of
                                    millions of dollars and an international community that now unites over 1.5
                                    million people worldwide.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    Oracle has already gained recognition from the prominent publication, The
                                    Entrepreneur, which included Manish in the top 10 most influential individuals in
                                    the cryptocurrency industry. The Entrepreneur is one of the most well-known
                                    and influential business media outlets, established in 1979. The website attracts
                                    nearly 15 million visitors every month, comparable to media giants like Forbes,
                                    Inc., and Bloomberg.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHistory;