import React, {useState} from "react";
import Styles from './styles.module.scss'

import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import PagIcon from "../pagIcon";



const Pagination = (props) => {
    const {links} = props;

    const navigate = useNavigate();
    const { t } = useTranslation();

    let {page} = useParams();

    const [prevHover, setPrevHover] = useState(false);
    const [nextHover, setNextHover] = useState(false);

    const pageLinkHandler = (url) =>{
        if (url){
            let pageNumber = url.slice(url.lastIndexOf('=') + 1, url.length);
            if (page !== pageNumber){
                navigate(`/news/page/${pageNumber}`);
            }
        }
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div
                    className={`${Styles.staticBtn} ${links?.[0].url ? Styles.staticBtnActive : null}`}
                    onMouseEnter={()=>{
                        if (links[0].url){
                            setPrevHover(true)
                        } else {
                            setPrevHover(false)
                        }
                    }}
                    onMouseLeave={()=>{
                        if (links[0].url){
                            setPrevHover(false)
                        } else {
                            setPrevHover(false)
                        }
                    }}
                    onClick={()=>{
                        if (links[0].url){
                            pageLinkHandler(links[0].url)
                        }
                    }}
                >
                    <PagIcon type='prev' isHover={prevHover}/>
                    <div className={`${Styles.btnText}`}>{t('Назад')}</div>
                </div>
                <div className={`${Styles.apyBtnWrap}`}>
                    {links?.map((item, index)=>{
                        if (index > 0 && index !== links.length - 1){
                            return (
                                <div
                                    className={`${Styles.apyBtnItem} ${page === item.label ? Styles.apyBtnItemActive : null}`}
                                    key={'apyBtnItem' + index}
                                    onClick={()=>{
                                        pageLinkHandler(item.url)
                                    }}
                                >
                                    {item.label}
                                </div>
                            )
                        } else return null
                    })}
                </div>
                <div
                    className={`${Styles.staticBtn} ${links?.[links.length - 1].url ? Styles.staticBtnActive : null}`}
                    onMouseEnter={()=>{
                        if (links[links.length - 1].url){
                            setNextHover(true)
                        } else {
                            setNextHover(false)
                        }
                    }}
                    onMouseLeave={()=>{
                        if (links[links.length - 1].url){
                            setNextHover(false)
                        } else {
                            setNextHover(false)
                        }
                    }}
                    onClick={()=>{
                        if (links[links.length - 1].url){
                            pageLinkHandler(links[links.length - 1].url)
                        }
                    }}
                >
                    <div className={`${Styles.btnText}`}>{t('Вперед')}</div>
                    <PagIcon type='next' isHover={nextHover}/>
                </div>
            </div>
        </section>
    );
};

export default Pagination;
