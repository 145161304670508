import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";





const WalletImportStep1 = (props) => {
    const {contentTypeHandler, phrase, setPhrase} = props;
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{t('Импорт кошелька')}</div>
                <div className={`${Styles.subTitle}`}>{t('Вставьте в поле ваш секретный ключ / Фраза восстановления')}</div>
                <div className={`${Styles.inputWrap}`}>
                    <input type="text" onChange={(e)=>{setPhrase(e.target.value)}} value={phrase}/>
                </div>
                <div
                    className={`${Styles.btn} ${phrase.length > 5 ? Styles.btnActive : null}`}
                    onClick={()=>{contentTypeHandler({type: 'import', step: 2})}}
                >
                    {t('Импортировать')}
                </div>
            </div>
        </section>
    );
};

export default WalletImportStep1;
