import React from "react";
import Styles from './styles.module.scss'

import createIcon from '../../../theme/assets/svg/omt_create_icon.svg';
import importIcon from '../../../theme/assets/svg/omt_import_icon.svg';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";



const WalletChoice = (props) => {
    const {contentTypeHandler, availableCurrencies} = props;
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.contentWrapItem}`} onClick={()=>{
                    if (availableCurrencies.length > 0){
                        contentTypeHandler({type: 'create', step: 1});
                    } else {
                        toast(t('У вас уже есть все доступные кошельки!'));
                    }
                }}>
                    <div className={`${Styles.contentWrapItemImgWrap}`}>
                        <img src={createIcon} alt="create"/>
                    </div>
                    <div className={`${Styles.contentWrapItemText}`}>{t('Создать новый кошелек')}</div>
                </div>
                <div className={`${Styles.contentWrapItem}`} onClick={()=>{contentTypeHandler({type: 'import', step: 1})}}>
                    <div className={`${Styles.contentWrapItemImgWrap}`}>
                        <img src={importIcon} alt="import"/>
                    </div>
                    <div className={`${Styles.contentWrapItemText}`}>{t('Импортировать кошелек')}</div>
                </div>
            </div>
        </section>
    );
};

export default WalletChoice;
