import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHoneyAi = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('HoneeAi')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>HoneeAi</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    For those seeking a reliable assistant in the world of cryptocurrency trading,
                                    we present our HoneeAI 2.0, designed to work on both CEX and DEX exchanges. Our
                                    bot is available in three subscription plans to meet the needs of different types
                                    of users.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The Standard subscription is ideal for those just starting their journey in
                                    automated
                                    trading. It allows you to upgrade your bot to a maximum level of 3, with an expected
                                    return of 3% to 6% per month. You will have access to three types of settings:
                                    moderate,
                                    medium, and high, so you can choose the optimal risk level for your portfolio.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The Pro subscription is designed for experienced users who want to maximize
                                    HoneeAI’s potential.
                                    With this plan, your bot can be upgraded to level 7, unlocking the potential to earn
                                    from 3% to
                                    an impressive 28% monthly. Advanced settings offer a range of tools for fine-tuning
                                    and optimizing
                                    trading strategies.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The Pro+ subscription is tailored for professionals who have mastered the Pro plan and
                                    are ready to unlock new levels of possibilities. With this subscription, your bot can
                                    also be upgraded to level 7, with potential earnings of 7% to an impressive 33% monthly.
                                    The expanded HoneeAI settings provide even greater optimization options, and the
                                    Auto-Reinvest feature allows for more convenient compounding.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    Choose the subscription that aligns with your goals and start your trading career with
                                    our bot!
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHoneyAi;