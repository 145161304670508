import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import roadmapImg from '../../../theme/assets/svg/omt_whitepaper_roadmap.svg';
import Fade from 'react-reveal/Fade';


const WhitePaperRoadmap = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Roadmap')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Roadmap</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={roadmapImg} alt="Roadmap"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperRoadmap;