import React, {useState} from "react";
import Styles from './styles.module.scss'
import CryptoIcon from "../cryptoIcon";

import listArrowIcon from '../../../theme/assets/svg/omt_list_arrow_icon.svg';
import {useTranslation} from "react-i18next";



const WalletCreateStep1 = (props) => {
    const {contentTypeHandler, currentNet, setCurrentNet, walletNetwork} = props;
    const { t } = useTranslation();

    const [listStatus, setListStatus] = useState(false);

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.title}`}>{t('Создание кошелька')}</div>
                <div className={`${Styles.listWrap}`}>
                    <div className={`${Styles.listTitle}`}>{t('Выберите сеть')}</div>
                    <div className={`${Styles.listPosWrap}`}>
                        <div className={`${Styles.listBox}`} onClick={()=>{setListStatus(!listStatus)}}>
                            <div
                                className={`${Styles.listBtn} ${listStatus ? Styles.listBtnActive : null}`}
                            >
                                <img src={listArrowIcon} alt="list arrow"/>
                            </div>
                            <div className={`${Styles.listResult}`}>
                                <div className={`${Styles.listResultImgWrap}`}>
                                    <CryptoIcon short_name={currentNet?.short_name}/>
                                </div>
                                <div className={`${Styles.listResultText}`}>{currentNet?.name}</div>
                            </div>
                        </div>
                        <div className={`${Styles.abslistWrap} ${listStatus ? Styles.abslistWrapActive : null}`}>
                            <div className={`${Styles.abslistBorderWrap}`}>
                                <div className={`${Styles.abslistBox}`}>
                                    {walletNetwork?.map((item, index)=>{
                                        return (
                                            <div
                                                className={`${Styles.listBoxItem}`}
                                                key={'walletNetwork' + index}
                                                onClick={()=>{
                                                    setCurrentNet({name: item.name, short_name: item.short_name, id: item.id});
                                                    setListStatus(false);
                                                }}
                                            >
                                                <div className={`${Styles.listBoxItemImgWrap}`}>
                                                    <CryptoIcon short_name={item.short_name}/>
                                                </div>
                                                <div className={`${Styles.listBoxItemText}`}>{item.name}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.btn}`} onClick={()=>{contentTypeHandler({type: 'create', step: 2})}}>{t('Далее')}</div>
                </div>
            </div>
        </section>
    );
};

export default WalletCreateStep1;
