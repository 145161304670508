import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    getExchangeData,
    getExchangeHistory,
    getExchangeHistorySuccess
} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";
// import ExchangeMain from "../../components/exchangeMain";
import ExchangeForm from "../../components/exchangeForm";
import ExchangeHistory from "../../components/exchangeHistory";


const ExchangePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const exchangeHistory = useSelector(store => store.cabinet.exchangeHistory);

    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getExchangeData());
    },[dispatch])

    useEffect(()=>{
        if (!exchangeHistory){
            dispatch(getExchangeHistory(1));
        } else if (exchangeHistory.current_page + 1 <= exchangeHistory.last_page && isScrolledToEnd){
            setIsScrolledToEnd(false);
            dispatch(getExchangeHistory(exchangeHistory.current_page + 1));
        }

    },[dispatch, isScrolledToEnd]) // eslint-disable-line

    useEffect(() => {
        return (()=>{dispatch(getExchangeHistorySuccess(false))})
    }, [dispatch]);

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Обмен')}/>
            <div className={`${Styles.contentWrap}`}>
                {/*<ExchangeMain/>*/}
                <ExchangeForm/>
                <ExchangeHistory scrollAction={setIsScrolledToEnd}/>
            </div>
        </section>
    );
};

export default ExchangePage;