import React, {useEffect} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import { useTranslation } from "react-i18next";
import {
    clearAllSwapRequestList,
    confirmBuySwapRequest,
    confirmBuySwapRequestSuccess,
    confirmSellSwapRequest,
    confirmSellSwapRequestSuccess,
    getAllSwapRequests,
    getSwapCommission,
    getSwapCommissionSuccess,
    setSwapRequestConfirmModalStatus,
} from "../../store/cabinet/action";
import { useDispatch, useSelector } from "react-redux";
import {walletShorter} from "../../helpers/walletShorter";
import {toast} from "react-toastify";

const SwapRequestConfirmModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const swapRequestConfirmModalStatus = useSelector(store => store.cabinet.swapRequestConfirmModalStatus);
    const swapMainData = useSelector(store => store.cabinet.swapMainData);
    const swapCommission = useSelector(store => store.cabinet.swapCommission);
    const confirmBuySwapRequestStatusStarted = useSelector(store => store.cabinet.confirmBuySwapRequestStatusStarted);
    const confirmSellSwapRequestStatusStarted = useSelector(store => store.cabinet.confirmSellSwapRequestStatusStarted);


    const mainBtnHandler = () => {
        const successHandler = () =>{
            closeModal();
            swapRequestConfirmModalStatus.data.direction === 'buy' ? toast(t('Ваша заявка на продажу в обработке, её статус вы можете отследить на странице заявок!')) : toast(t('Ваша заявка на покупку в обработке, её статус вы можете отследить на странице заявок!'));
            dispatch(clearAllSwapRequestList(true));
            dispatch(getAllSwapRequests(1, swapRequestConfirmModalStatus.currentFilter));
        }

        if (!confirmBuySwapRequestStatusStarted && !confirmSellSwapRequestStatusStarted){
            if (swapRequestConfirmModalStatus?.data?.direction === 'buy'){
                dispatch(confirmSellSwapRequest(swapRequestConfirmModalStatus.data.id, successHandler))
            } else {
                dispatch(confirmBuySwapRequest(swapRequestConfirmModalStatus.data.id, successHandler))
            }
        }
    };

    const clearForm = () => {
        dispatch(getSwapCommissionSuccess(false));
        dispatch(confirmBuySwapRequestSuccess(false));
        dispatch(confirmSellSwapRequestSuccess(false));
    };

    const closeModal = () => {
        dispatch(setSwapRequestConfirmModalStatus({status: false, data: false, currentFilter: false}));
        clearForm();
    };

    const isCommissionSuccess = () =>{
        if (swapRequestConfirmModalStatus?.data?.direction === 'sell'){
            return Number(swapMainData?.honee?.amount) >= swapMainData?.honee_commission
        } else {
            return Number(swapCommission?.wallet?.amount) >= swapCommission?.commission
        }

    }

    useEffect(() => {
        if (swapRequestConfirmModalStatus?.data?.direction === 'buy'){
            dispatch(getSwapCommission(swapRequestConfirmModalStatus?.data?.amount_buy))
        }
    }, [swapRequestConfirmModalStatus, dispatch]);


    return (
        <Popup
            open={swapRequestConfirmModalStatus.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={closeModal}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title} ${Styles.titleWSF}`}>{swapRequestConfirmModalStatus?.data?.direction === 'buy' ? t('Подтверждение продажи') : t('Подтверждение покупки')}</div>
                    <div className={`${Styles.modalContentWrapRepositionBox}`}>
                        <div className={`${Styles.modalContentWrapItem}`}>
                            <div className={`${Styles.modalContentWrapItemName}`}>{t('Тип сделки')}:</div>
                            <div
                                className={`${Styles.modalContentWrapItemValue} ${swapRequestConfirmModalStatus?.data?.direction === 'buy' ? Styles.modalContentWrapItemValueSell : Styles.modalContentWrapItemValueBuy}`}>{swapRequestConfirmModalStatus?.data?.direction === 'buy' ? t('Продать') : t('Купить')}</div>
                        </div>
                        <div className={`${Styles.modalContentWrapItem}`}>
                            <div className={`${Styles.modalContentWrapItemName}`}>{t('Количество')} <span>OMT20</span>:
                            </div>
                            <div className={`${Styles.modalContentWrapItemValue}`}>{swapRequestConfirmModalStatus?.data?.direction === 'buy' ? swapRequestConfirmModalStatus?.data?.amount_buy * 1 : swapRequestConfirmModalStatus?.data?.amount_sell * 1}</div>
                        </div>
                        <div className={`${Styles.modalContentWrapItem}`}>
                            <div className={`${Styles.modalContentWrapItemName}`}>{t('Эквивалент в')} <span>Honee</span>:
                            </div>
                            <div className={`${Styles.modalContentWrapItemValue}`}>{swapRequestConfirmModalStatus?.data?.direction === 'buy' ? swapRequestConfirmModalStatus?.data?.amount_sell * 1 : swapRequestConfirmModalStatus?.data?.amount_buy * 1}</div>
                        </div>
                        <div className={`${Styles.modalContentWrapItem}`}>
                            <div className={`${Styles.modalContentWrapItemName}`}>{t('Адрес кошелька')}:</div>
                            <div
                                className={`${Styles.modalContentWrapItemValue} ${Styles.modalContentWrapItemValueWallet}`}>{walletShorter(swapMainData?.omt20?.address)}</div>
                        </div>
                        <div className={`${Styles.modalContentWrapItem}`}>
                            <div className={`${Styles.modalContentWrapItemName}`}>{t('Комиссия')}:</div>
                            {swapRequestConfirmModalStatus?.data?.direction === 'sell' ?
                                <div className={`${Styles.modalContentWrapItemValue}`}>
                                    {swapMainData.honee_commission} Honee {isCommissionSuccess() ?
                                    <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> :
                                    <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span>}
                                </div> :
                                <div className={`${Styles.modalContentWrapItemValue}`}>
                                    <span>{swapCommission?.commission || swapCommission?.commission === 0 ? `${Number(swapCommission?.commission)} ${swapCommission?.currency?.short_name}` : t('Загрузка...')} {swapCommission?.commission ? isCommissionSuccess() ?
                                        <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span
                                            className={`${Styles.colorRed}`}>({t('Недостаточно')})</span> : null}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={`${Styles.mainBtn} ${isCommissionSuccess() ? Styles.mainBtnActive : null}`} onClick={() => {mainBtnHandler()}}>{t('Подтвердить')}</div>
                </div>
            </div>
        </Popup>
    );
};

export default SwapRequestConfirmModal;
