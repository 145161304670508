import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHAanalysing = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Analysing')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Analysing</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Whale Linear Regression (WLR)</div>
                                <div className={`${Styles.paragraph}`}>
                                    Linear regression is a <span className={`${Styles.link}`} onClick={()=>{window.open("https://trendspider.com/learning-center/statistical-trading-the-basics/", "_blank")}}>statistical</span> technique used to model the relationship
                                    between a dependent variable and one or more independent variables. In the
                                    context of trading and investing, linear regression serves as
                                    a <span className={`${Styles.link}`} onClick={()=>{window.open("https://trendspider.com/learning-center/navigating-the-trading-world-with-technical-indicators-a-comprehensive-guide/", "_blank")}}>technical indicator</span> to predict future prices based on historical data. Traders
                                    and investors can forecast price levels and make informed decisions by
                                    evaluating the connection between price (dependent variable) and time
                                    (independent variable).
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Linear Regression in Financial Markets</div>
                                <div className={`${Styles.paragraph}`}>
                                    Though linear regression has its roots in the early 19th century, it was later
                                    adapted for financial market applications to help traders and investors
                                    anticipate price <span className={`${Styles.link}`} onClick={()=>{window.open("https://trendspider.com/learning-center/how-to-draw-trendlines/", "_blank")}}>trends</span> and make strategic choices. Today, it is widely used in
                                    trading <span className={`${Styles.link}`} onClick={()=>{window.open("https://trendspider.com/learning-center/technical-analysis-strategies/", "_blank")}}>strategies</span> to analyze price movements.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Formula for Linear Regression Indicator</div>
                                <div className={`${Styles.paragraph}`}>
                                    The Linear Regression Indicator (LRI) can be calculated using the following<br/>
                                    formula:<br/>
                                    LRI = (Ending Value of Linear Regression Line) / (Number of Bars)<br/>
                                    For example, a 20-period LRI would equal the ending value of a linear
                                    regression line covering 20 bars.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Interpreting Linear Regression Results</div>
                                <div className={`${Styles.paragraph}`}>
                                    The LRI displays the expected price, statistically speaking, at the end of a
                                    specified number of bars. A positive slope signifies an upward trend, whereas a
                                    negative slope indicates a downward trend. The slope’s steepness reveals the
                                    strength of the trend.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.title2}`}>Interpreting Signals</div>
                                <div className={`${Styles.paragraph}`}>
                                    Traders employ the LRI to detect potential trend reversals, <span className={`${Styles.link}`} onClick={()=>{window.open("https://trendspider.com/learning-center/what-are-support-and-resistance-in-trading/", "_blank")}}>support and
                                    resistance</span> levels, and price targets. An upward-sloping LRI with the price above
                                    the regression line may suggest a bullish trend. In contrast, a downward-sloping LRI with the price below the line could indicate a bearish trend.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHAanalysing;