import React from "react";
import Styles from './styles.module.scss';

import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import newsReadIcon from '../../theme/assets/svg/omt_news_read_icon.svg';
import Moment from "react-moment";
import DOMPurify from "dompurify";
import {linkTranslate} from "../../helpers/linkTranslite";
import {useNavigate} from "react-router-dom";
import Pagination from "../_UI/pagination";
// import newsLinkIcon from '../../theme/assets/svg/omt_news_link_icon.svg';


const NewsMain = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const mainNews = useSelector(store => store.main.mainNews);

    const postLinkHandler = (title, id) => {
        const correctUrl = linkTranslate(title, id);
        navigate(`/news/${correctUrl}`);
    };

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.title2} ${Styles.colorOrange} ${Styles.titleWSF}`}>{t('Все новости')}</div>
                <div className={`${Styles.contentBox}`}>
                    {mainNews?.data?.length > 0 ? mainNews?.data?.map((item, index)=>{
                        return (
                            <div className={`${Styles.contentBoxItem}`} key={'mainNews' + index} onClick={()=>{postLinkHandler(item.title, item.id)}}>
                                <div className={`${Styles.contentBoxItemImgWrap}`}>
                                    <img src={item.picture} alt={item.title}/>
                                </div>
                                <div className={`${Styles.contentBoxItemData}`}>
                                    <div className={`${Styles.dateWrap}`}>
                                        <div className={`${Styles.date}`}><Moment format="DD/MM/YYYY">{item.created_at}</Moment></div>
                                        <div className={`${Styles.read}`}>
                                            <div className={`${Styles.readImgWrap}`}>
                                                <img src={newsReadIcon} alt={item.title}/>
                                            </div>
                                            {item.read_time ? item.read_time : '2'} minutes
                                        </div>
                                    </div>
                                    <div className={`${Styles.title3} ${Styles.titleWSF}`}>{item.title}</div>
                                    <div
                                        className={`${Styles.contentBoxItemDescription}`}
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(item.content),
                                        }}
                                    />
                                    <div className={`${Styles.contentBoxItemCategory}`}>{item.category?.name}</div>
                                </div>
                            </div>
                        )
                    }) : null}
                </div>
                <Pagination links={mainNews?.links}/>
            </div>
        </section>
    );
};

export default NewsMain;
