import React, {useEffect} from "react";
import Fade from 'react-reveal/Fade';
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";


const WhitePaperMAmarketStatus = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Market Status')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Current Market Status</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Currently, participants in the cryptocurrency market agree that the market is in
                                    a bearish phase. Many experts claim that the period of low asset prices and
                                    slow growth, known as the "crypto winter," has arrived. Cryptocurrencies, like
                                    other markets, experienced a significant downturn in 2022. Assets are affected
                                    by geopolitical risks, reduction in economic stimulus by authorities, as well as
                                    prospects of a possible recession.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Common FUD (Fear, Uncertainty, and Doubt) factors, including negative
                                    forecasts and rumors, also influence the market. Recently, for example, the
                                    market sharply reacted to news of a possible bankruptcy of the Coinbase
                                    exchange, which was later refuted by the company itself.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    At the beginning of the year, many investors chose to close their positions and
                                    wait out the turbulence in cash or in the most reliable assets, even at the cost
                                    of reducing profits. Currently, Bitcoin and Ethereum have lost around 70% of
                                    their all-time highs. The first cryptocurrency has returned to the values of late
                                    2017. The price decline started after reaching new highs in mid-November 2021
                                    and accelerated in the spring of 2022. Since then, market players have been
                                    testing opportunities for growth, but no significant breakthroughs have
                                    occurred.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    In addition to price declines, significant changes in the sector are associated
                                    with increased regulation of cryptocurrency markets by governments. In
                                    September 2021, the People's Bank of China banned cryptocurrency
                                    transactions, citing them as a "threat to China's financial system." The ban
                                    accelerated the already noticeable outflow of digital assets from East Asia.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    At the same time, cryptocurrencies are still in a gray area or completely banned
                                    in some countries. For example, the use of cryptocurrencies is prohibited in
                                    China, Iran, and Bolivia. In Russia, there is a ban on the issuance and circulation
                                    of cryptocurrencies, but law-abiding users only face administrative liability.
                                    It is also worth mentioning the trend of increasing interest in cryptocurrencies
                                    from institutional investors, such as banks, hedge funds, and pension funds.
                                    For example, MicroStrategy acquired bitcoins worth over $1.5 billion, and Tesla
                                    invested nearly $1.5 billion in Bitcoin. Additionally, some companies have
                                    started accepting cryptocurrencies as payment for their goods and services,
                                    such as PayPal and Visa.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Despite the challenges faced by the cryptocurrency market, despite the new
                                    hurdles, cryptocurrencies retain their core advantages and potential for rapid
                                    growth. They are recognized for their significant investment potential and the
                                    ability to be used as an alternative currency.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Moreover, in 2021, Bitcoin was officially recognized as a legal payment
                                    instrument at the national level. It is now used as legal tender in El Salvador.
                                    Similar reforms are being discussed in other countries as well.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                The key advantages of cryptocurrency payments include:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <Fade right duration={600}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(a)</div>
                                        Independence from central banks and governments, providing greater
                                        freedom in using one's own funds;
                                    </div>
                                </Fade>
                                <Fade right duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(b)</div>
                                        Guaranteed control over funds, as users themselves act as custodians
                                        of their cryptocurrency assets.
                                    </div>
                                </Fade>
                                <Fade right duration={800}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(c)</div>
                                        The ability to make payments anytime and to any recipient without
                                        borders or restrictions;
                                    </div>
                                </Fade>
                                <Fade right duration={900}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(d)</div>
                                        Anonymity of payments, which is an important aspect for those who
                                        value the confidentiality of their financial transactions.
                                    </div>
                                </Fade>
                                <Fade right duration={1000}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(e)</div>
                                        Low transaction costs compared to traditional financial instruments,
                                        allowing for cost and time savings in conducting transactions.
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The regulation of cryptocurrencies aims to prevent money laundering, which is
                                    one of the main tasks. Authorities emphasize that without strict rules,
                                    cryptocurrencies can become a means for fraud and criminal activities.
                                    Opponents of regulation argue that it can limit the freedom of transactions,
                                    which is one of the key advantages of cryptocurrencies.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    However, in practice, such concerns are of little significance, as even the
                                    blocking of the Tornado Cash mixer did not prevent the platform from
                                    continuing to operate despite sanctions from US authorities. Despite new laws,
                                    cryptocurrency transactions remain free and decentralized, as changing the
                                    technological foundations of cryptocurrencies is not easy.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Cryptocurrency owners can still transfer them to any wallet anywhere in the
                                    world without any restrictions. No one can stop or freeze a transaction, and no
                                    one can force you to explain the purpose of the payment or require paperwork
                                    for reporting.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Non-exchange wallets, especially "cold wallets," guarantee complete protection
                                    against blockages since the funds in them belong solely to the wallet owner.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Sending cryptocurrencies can be done without the need for approval from
                                    banks or regulators. To make a payment, it is sufficient to know the recipient's
                                    wallet address.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Cryptocurrencies also facilitate support for charitable and public organizations,
                                    as well as businesses in regions where financial services are not accessible to
                                    all. For example, in Africa, where banking services are not widely available,
                                    cryptocurrencies can successfully replace fiat payments. Additionally, digital
                                    assets facilitate Cuba's trade with countries where capital movement is
                                    restricted.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    However, despite the belief that cryptocurrencies are entirely anonymous, it is
                                    important to remember that transactions using digital assets can be traced. It is
                                    not as straightforward as with traditional payment methods, but it is still
                                    possible.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Furthermore, if you link your cryptocurrency assets to the traditional financial
                                    system, you risk losing your anonymity. Using a bank account to deposit funds
                                    into an exchange account or withdraw funds to a card can lead to deanonymization of you and your digital assets.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    However, if you only use cryptocurrencies for transfers between wallets, you
                                    can maintain your anonymity and avoid leaking your personal data.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Moreover, cryptocurrencies can be a more advantageous option for conducting
                                    payments. They are not subject to taxes and do not require additional fees, as
                                    is the case with traditional payment methods. Miners involved in transaction
                                    processing can offer their services at a lower cost compared to payment
                                    systems, especially for international transfers.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    Arthur Hayes, an American entrepreneur and co-founder of the BitMEX
                                    exchange, believes that cryptocurrencies are the best asset for periods of
                                    acute instability. Even in the most pessimistic scenarios for the future, where
                                    governments tightly restrict the free movement of capital, cryptocurrencies
                                    remain an independent means of payment and a means of savings. They cannot
                                    be restricted, confiscated, or controlled in the same way as "fiat" money.
                                    Your digital assets will never cease to be yours.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperMAmarketStatus;