import React from "react";
import Styles from './styles.module.scss';




const CommissionAlert = () => {

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <div className={`${Styles.note}`}>
                    To make a cryptocurrency transfer, you need to pay <br/>
                    a commission fee in TRX (Tron)
                </div>
            </div>
        </section>
    );
};

export default CommissionAlert;
