import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperMission = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Mission')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Mission of Oracle Meta Technologies</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Oracle Meta Technologies is an innovative project that has created the most
                                    reliable and functional cryptocurrency wallet in the world. Our goal is to ensure
                                    maximum security for users and provide them with additional opportunities for
                                    investing and earning with cryptocurrencies.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    We believe that cryptocurrency is the future, and therefore, we have developed
                                    a convenient and secure tool for storing and using cryptocurrency assets -
                                    Oracle. This cryptocurrency is created using blockchain technology to address
                                    issues with online payments. The goal of Oracle is to facilitate the use of
                                    cryptocurrency not only for purchasing anything anywhere but also for mining
                                    coins on a smartphone. Users are the sole owners of their coins and can freely
                                    mine, spend, transfer, store, inherit, and perform other operations cheaply,
                                    quickly, and securely.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The Oracle cryptocurrency system has developed and implemented new
                                    technologies to address the shortcomings in the concepts of Bitcoin and other
                                    coins. Oracle does not compete with other coins on the existing market but
                                    creates its own market and already occupies leading positions.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    We are confident that our wallet and the Oracle cryptocurrency will become the
                                    primary tools for storing and using cryptocurrency assets in the future. In our
                                    wallet, we have integrated the latest blockchain and artificial intelligence
                                    technologies, significantly enhancing its functionality and user-friendliness.
                                    Additionally, we plan to become the primary fiat on-ramp for our
                                    cryptocurrency, offering fast transactions, low volatility, and extensive payment
                                    mechanisms such as debit cards, ATMs, and others.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperMission;