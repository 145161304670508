import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperCommunity = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Community')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>The Oracle Meta Technologies community</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The Oracle community is a key factor in the growth of the cryptocurrency
                                    and a competitive advantage for the project. The OMT Team places great
                                    importance on developing its community from the project's inception.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Currently, the number of users of Oracle products exceeds one million, with
                                    each user being part of a global crypto community. Community members
                                    engage with various Oracle products such as AI, wallets, AI-based bots, and
                                    staking, contributing to the increased demand for the Oracle cryptocurrency.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Community activity directly influences the growth of the Oracle price since
                                    all actions require the use of the Oracle cryptocurrency. The combination of
                                    these factors positively impacts the growth of the Oracle Meta Technologies
                                    project, as each Oracle transaction increases demand for the cryptocurrency
                                    while simultaneously reducing its supply in the market.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    Oracle has one of the largest and most multicultural crypto communities in
                                    the market. All participants can engage in events and educational programs
                                    organized by the Oracle team, fostering a strong and loyal community. The
                                    Oracle team places significant importance on community development,
                                    considering it one of the main drivers of the project's growth.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperCommunity;