import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHAmethodOfTrading = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Method of trading')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Method of trading</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.bolded}>Intra-exchange arbitrage</span> is the simplest and most accessible type of
                                    arbitrage, the essence of which is the regular resale of cryptocurrency within
                                    one exchange.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.bolded}>Inter-exchange arbitrage</span> is the same as intra-exchange, but this time we will
                                    use not one, but several exchanges. Occasionally, the same asset on two
                                    different exchanges will have varying prices, and we'll take advantage of this,
                                    earning on the difference.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.bolded}>Multi-currency inter-exchange arbitrage</span> combines inter-exchange and intra-exchange arbitrages, meaning a trader conducts a series of transactions using
                                    multiple cryptocurrencies and multiple cryptocurrency exchanges. This type of
                                    arbitrage is technically more complex.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHAmethodOfTrading;