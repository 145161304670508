import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperOEindex = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Technical Scientific Analysis of Our Cryptocurrency Index')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Technical Scientific Analysis of Our Cryptocurrency Index</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Introduction</div>
                                <div className={`${Styles.paragraph}`}>
                                    A cryptocurrency index, analogous to the S&P 500, is a measure that allows
                                    tracking the state and dynamics of the cryptocurrency market. In this article,
                                    we consider the creation and analysis of an index of the 20 most popular
                                    cryptocurrencies, using data from October 10, 2023, as the base point. The
                                    main goal is to determine the change in the total market capitalization of the
                                    selected cryptocurrencies as of the current date (June 23, 2024) and calculate
                                    the corresponding index.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>Selection of Cryptocurrencies</div>
                            <div className={`${Styles.paragraph}`}>
                                To create the index, the following 20 cryptocurrencies were selected:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <Fade right duration={500}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>1.</div>
                                        Bitcoin (BTC)
                                    </div>
                                </Fade>
                                <Fade right duration={550}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>2.</div>
                                        Ethereum (ETH)
                                    </div>
                                </Fade>
                                <Fade right duration={600}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>3.</div>
                                        XRP (Ripple)
                                    </div>
                                </Fade>
                                <Fade right duration={650}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>4.</div>
                                        Litecoin (LTC)
                                    </div>
                                </Fade>
                                <Fade right duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>5.</div>
                                        Solana (SOL)
                                    </div>
                                </Fade>
                                <Fade right duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>6.</div>
                                        Binance Coin (BNB)
                                    </div>
                                </Fade>
                                <Fade right duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>7.</div>
                                        Toncoin (TON)
                                    </div>
                                </Fade>
                                <Fade right duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>8.</div>
                                        Dogecoin (DOGE)
                                    </div>
                                </Fade>
                                <Fade right duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>9.</div>
                                        Tron (TRX)
                                    </div>
                                </Fade>
                                <Fade right duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>10.</div>
                                        Polkadot (DOT)
                                    </div>
                                </Fade>
                                <Fade right duration={750}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>11.</div>
                                        Cosmos (ATOM)
                                    </div>
                                </Fade>
                                <Fade right duration={800}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>12.</div>
                                        Polygon (MATIC)
                                    </div>
                                </Fade>
                                <Fade right duration={850}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>13.</div>
                                        Aptos (APT)
                                    </div>
                                </Fade>
                                <Fade right duration={900}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>14.</div>
                                        Arbitrum (ARB)
                                    </div>
                                </Fade>
                                <Fade right duration={950}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>15.</div>
                                        Sui (SUI)
                                    </div>
                                </Fade>
                                <Fade right duration={1000}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>16.</div>
                                        Filecoin (FIL)
                                    </div>
                                </Fade>
                                <Fade right duration={1050}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>17.</div>
                                        Avalanche (AVAX)
                                    </div>
                                </Fade>
                                <Fade right duration={1100}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>18.</div>
                                        Cardano (ADA)
                                    </div>
                                </Fade>
                                <Fade right duration={1150}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>19.</div>
                                        Chainlink (LINK)
                                    </div>
                                </Fade>
                                <Fade right duration={1200}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>20.</div>
                                        Near Protocol (NEAR)
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Market Capitalization</div>
                                <div className={`${Styles.paragraph}`}>
                                    The market capitalization of each cryptocurrency is calculated as the product
                                    of the price of one coin and the total number of coins in circulation:
                                </div>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.colorOrange}>Market Capitalization</span> = Cryptocurrency Price * Total Number of Coins in Circulation<br/>
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Total Market Capitalization</div>
                                <div className={`${Styles.paragraph}`}>
                                    The total market capitalization of a set of cryptocurrencies is calculated as the
                                    sum of the market capitalizations of each individual cryptocurrency:
                                </div>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.colorOrange}>Total Market Capitalization</span> = (20 | Σ | i=1) Market Capitalization<br/>
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Cryptocurrency Index</div>
                                <div className={`${Styles.paragraph}`}>
                                    The index is calculated based on the ratio of the current total market
                                    capitalization to the base total market capitalization. The base index value is
                                    assumed to be 1000 on the initial date (October 10, 2023):
                                </div>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.colorOrange}>Index</span> = (Current Total Market Capitalization / Base Total Market Capitalization) * 1000<br/>
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>Data Collection</div>
                            <div className={`${Styles.paragraph}`}>
                                To conduct the analysis, the following data were collected:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <Fade left duration={500}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(a)</div>
                                        Cryptocurrency prices as of October 10, 2023.
                                    </div>
                                </Fade>
                                <Fade left duration={600}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(b)</div>
                                        Cryptocurrency prices as of June 23, 2024.
                                    </div>
                                </Fade>
                                <Fade left duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(c)</div>
                                        Number of coins in circulation for each cryptocurrency.
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The data were obtained from verified sources such as CoinMarketCap and
                                    other authoritative cryptocurrency data resources.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>Calculations:</div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <Fade left duration={500}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>1.</div>
                                        Market Capitalization as of October 10, 2023:<br/>
                                        <span className={Styles.colorOrange}>Market Capitalization</span> (October 10, 2023) = Price (October 10, 2023) * Number of Coins
                                    </div>
                                </Fade>
                                <Fade left duration={600}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>2.</div>
                                        Market Capitalization as of June 23, 2024:<br/>
                                        <span className={Styles.colorOrange}>Market Capitalization</span> (June 23, 2024) = Price (June 23, 2024) * Number of Coins
                                    </div>
                                </Fade>
                                <Fade left duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>3.</div>
                                        Total Market Capitalization as of October 10, 2023:<br/>
                                        <span className={Styles.colorOrange}>Total Market Capitalization</span> (October 10, 2023) = (20 | Σ | i=1) Price (October 10, 2023) * Number of Coins
                                    </div>
                                </Fade>
                                <Fade left duration={800}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>4.</div>
                                        Total Market Capitalization as of June 23, 2024:<br/>
                                        <span className={Styles.colorOrange}>Total Market Capitalization</span> (June 23, 2024) = (20 | Σ | i=1) Price (June 23, 2024) * Number of Coins
                                    </div>
                                </Fade>
                                <Fade left duration={900}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>5.</div>
                                        Index Calculation:<br/>
                                        <span className={Styles.colorOrange}>Index</span> = (Total Market Capitalization (June 23, 2024) / Total Market Capitalization (October 10, 2023)) * 1000
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>Market Capitalization as of October 10, 2023</div>
                            <div className={`${Styles.paragraphTable}`}>
                                <div className={`${Styles.paragraphTableTitleRow}`}>
                                    <div className={`${Styles.paragraphTableTitleRowItem} ${Styles.colorOrange}`}><span className={`${Styles.paragraphTableTitleRowItemM}`}>Currency</span><span className={`${Styles.paragraphTableTitleRowItemD}`}>Cryptocurrency</span></div>
                                    <div className={`${Styles.paragraphTableTitleRowItem} ${Styles.colorOrange}`}>Price</div>
                                    <div className={`${Styles.paragraphTableTitleRowItem} ${Styles.colorOrange}`}>Number of Coins</div>
                                    <div className={`${Styles.paragraphTableTitleRowItem} ${Styles.colorOrange}`}>Market Capitalization</div>
                                </div>
                                <div className={`${Styles.paragraphTableBox}`}>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>BTC</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$27,500</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>19,400,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$533,500,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>ETH</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$1,500</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>120,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$180,000,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>XRP</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.52</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>52,800,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$27,456,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>LTC</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$65</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>73,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$4,745,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>SOL</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$21</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>380,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$7,980,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>BNB</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$220</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>155,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$34,100,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>TON</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$2.00</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>5,000,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$10,000,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>DOGE</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.06</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>140,000,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$8,400,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>TRX</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.07</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>89,000,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$6,230,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>DOT</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$4.00</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>1,000,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$4,000,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>ATOM</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$8</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>286,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$2,288,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>MATIC</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.70</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>9,300,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$6,510,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>APT</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$6</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>200,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$1,200,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>ARB</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$1.1</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>4,000,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$1,100,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>SUI</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.6</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>10,000,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$6,000,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>FIL</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$4.5</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>420,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$1,890,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>AVAX</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$10</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>360,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$3,600,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>ADA</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.3</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>35,000,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$10,500,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>LINK</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$5</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>1,000,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$5,000,000,000</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>NEAR</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$1.2</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>900,000,000</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$1,080,000,000</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The total market capitalization as of October 10, 2023 is:<br/>
                                    <span className={Styles.colorOrange}>Total Market Capitalization</span> (October 10, 2023) = 879,979,000,000
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>Market Capitalization as of June 23, 2024</div>
                            <div className={`${Styles.paragraphTable}`}>
                                <div className={`${Styles.paragraphTableTitleRow}`}>
                                    <div className={`${Styles.paragraphTableTitleRowItem} ${Styles.colorOrange}`}><span className={`${Styles.paragraphTableTitleRowItemM}`}>Currency</span><span className={`${Styles.paragraphTableTitleRowItemD}`}>Cryptocurrency</span></div>
                                    <div className={`${Styles.paragraphTableTitleRowItem} ${Styles.colorOrange}`}>Price</div>
                                    <div className={`${Styles.paragraphTableTitleRowItem} ${Styles.colorOrange}`}>Number of Coins</div>
                                    <div className={`${Styles.paragraphTableTitleRowItem} ${Styles.colorOrange}`}>Market Capitalization</div>
                                </div>
                                <div className={`${Styles.paragraphTableBox}`}>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>BTC</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$61,100</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>19,715,981</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$1,204,895,969,100</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>ETH</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$3,282</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>122,275,693</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$401,182,927,026</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>XRP</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.4718</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>55,618,185,850</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$26,234,012,353</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>LTC</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$69.96</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>74,676,175</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$5,224,420,358</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>SOL</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$124.22</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>462,139,158</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$57,413,176,611</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>BNB</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$564.63</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>147,565,893</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$83,321,608,352</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>TON</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$7.18</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>2,458,276,089</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$17,641,077,278</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>DOGE</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.1166</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>144,839,306,384</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$16,891,110,422</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>TRX</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.1183</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>87,238,432,759</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$10,316,349,467</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>DOT</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$5.49</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>1,437,953,431</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$7,893,892,334</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>ATOM</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$6.53</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>390,930,671</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$2,551,769,279</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>MATIC</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.5449</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>9,904,426,499</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$5,396,608,616</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>APT</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$6.52</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>451,872,457</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$2,946,205,635</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>ARB</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.7575</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>3,231,588,116</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$2,448,292,588</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>SUI</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.8322</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>2,426,143,881</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$2,019,015,002</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>FIL</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$4.11</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>564,842,632</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$2,321,684,425</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>AVAX</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$24.21</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>393,748,357</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$9,533,401,662</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>ADA</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$0.3694</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>35,743,198,273</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$13,206,279,898</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>LINK</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$12.86</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>608,099,970</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$7,816,085,442</div>
                                    </div>
                                    <div className={`${Styles.paragraphTableBoxRow}`}>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>NEAR</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$5.29</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>1,091,247,560</div>
                                        <div className={`${Styles.paragraphTableBoxRowItem}`}>$5,774,701,524</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The total market capitalization as of June 23, 2024 is:<br/>
                                    <span className={Styles.colorOrange}>Total Market Capitalization</span> (June 23, 2024) = 1,937,730,496,372
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Index Calculation</div>
                                <div className={`${Styles.paragraph}`}>
                                    Using the index formula, we calculate the current index value:<br/>
                                    <span className={Styles.colorOrange}>Index</span> = (Total Market Capitalization (June 23, 2024) / Total Market Capitalization (October 10, 2023)) * 1000<br/>
                                    <span className={Styles.colorOrange}>Index</span> = (1,937,730,496,372 / 879,979,000,000) * 1000 = <span className={Styles.colorOrange}>2203.09</span><br/>
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>Discussion</div>
                                <div className={`${Styles.paragraph}`}>
                                    The calculated index shows a significant increase from the base date of
                                    October 10, 2023. This indicates that the total market capitalization of the
                                    selected cryptocurrencies has more than doubled over the given period. Such
                                    growth can be attributed to various factors, including increased investor
                                    interest, wider adoption of cryptocurrencies, and positive market trends.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Advanced AI-Based Rebalancing Technology</div>
                                <div className={`${Styles.paragraph}`}>
                                    Our cryptocurrency index is equipped with the latest cutting-edge technologies,
                                    ensuring optimal performance and reliability. One of the key innovations in our
                                    index is the use of advanced artificial intelligence (AI) for portfolio rebalancing.
                                    This sophisticated AI-driven mechanism allows us to maintain low volatility and
                                    practically eliminate the possibility of significant index downturns.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>How AI Enhances Portfolio Rebalancing</div>
                                <div className={`${Styles.paragraph}`}>
                                    The AI system continuously monitors the market conditions, evaluating a multitude
                                    of factors such as price fluctuations, trading volumes, and market trends.
                                    By analyzing this data in real-time, the AI can make precise adjustments to
                                    the portfolio composition, ensuring that the index remains balanced and aligned
                                    with the market’s current state.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>The rebalancing process involves several critical steps:</div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <Fade left duration={500}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>1.</div>
                                        <span className={Styles.colorOrange}>Data Collection and Analysis: </span>
                                        The AI collects data from multiple reliable sources, analyzing historical
                                        performance, market sentiment, and other relevant metrics. This comprehensive
                                        analysis helps in identifying trends and predicting potential market movements.
                                    </div>
                                </Fade>
                                <Fade left duration={600}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>2.</div>
                                        <span className={Styles.colorOrange}>Dynamic Adjustment: </span>
                                        Based on the analyzed data, the AI dynamically adjusts the weightings of the
                                        individual cryptocurrencies within the index. This ensures that the portfolio
                                        remains diversified and mitigates the risk of any single asset disproportionately
                                        impacting the index’s performance.
                                    </div>
                                </Fade>
                                <Fade left duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>3.</div>
                                        <span className={Styles.colorOrange}>Risk Management: </span>
                                        The AI employs advanced risk management algorithms to detect and respond to market
                                        anomalies. By doing so, it can swiftly reallocate assets to more stable options,
                                        minimizing the impact of market volatility on the index.
                                    </div>
                                </Fade>
                                <Fade left duration={800}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>4.</div>
                                        <span className={Styles.colorOrange}>Continuous Learning: </span>
                                        Our AI system is built to learn and adapt continuously. It refines its strategies
                                        based on new data and market developments, ensuring that the rebalancing process
                                        becomes more accurate and effective over time.
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.title2}`}>Proprietary Innovation</div>
                                <div className={`${Styles.paragraph}`}>
                                    The formula and methodology behind our AI-driven rebalancing
                                    are proprietary innovations that we have meticulously developed.
                                    Due to the competitive nature of the financial technology industry and
                                    to protect our intellectual property, we cannot disclose the specific
                                    details of this formula. This confidentiality ensures that our unique approach
                                    remains protected and continues to provide our clients with a distinct competitive edge.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>Benefits of AI-Based Rebalancing</div>
                            <div className={`${Styles.title2}`}>The implementation of AI in our rebalancing process offers several significant benefits:</div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <Fade left duration={500}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(a)</div>
                                        <span className={Styles.colorOrange}>Low Volatility: </span>
                                        By continuously monitoring and adjusting the portfolio, the AI helps to keep
                                        volatility at a minimum. This stability is crucial for investors who seek
                                        consistent returns without the erratic swings often associated with cryptocurrency markets.
                                    </div>
                                </Fade>
                                <Fade left duration={600}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(b)</div>
                                        <span className={Styles.colorOrange}>Reduced Risk: </span>
                                        The AI’s ability to predict and respond to market changes in real-time
                                        significantly reduces the risk of substantial losses. This proactive approach
                                        ensures that the index remains robust even during periods of market uncertainty.
                                    </div>
                                </Fade>
                                <Fade left duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(c)</div>
                                        <span className={Styles.colorOrange}>Optimized Performance: </span>
                                        The dynamic nature of the AI-driven rebalancing ensures that the index is always
                                        optimized for current market conditions. This continuous optimization leads to
                                        better overall performance compared to static portfolio management strategies.
                                    </div>
                                </Fade>
                                <Fade left duration={800}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(d)</div>
                                        <span className={Styles.colorOrange}>Confidence and Trust: </span>
                                        Investors can have confidence in the index’s ability to maintain stability and
                                        provide reliable returns, knowing that it is backed by the latest technological
                                        advancements and innovative AI solutions.
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    In summary, our advanced AI-based rebalancing technology is a cornerstone of the
                                    cryptocurrency index, offering enhanced stability, reduced risk, and optimized performance.
                                    This proprietary technology sets our index apart from others, providing investors with a
                                    unique and reliable investment tool in the dynamic world of digital assets.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.title2}`}>Conclusion</div>
                                <div className={`${Styles.paragraph}`}>
                                    In this article, we conducted an analysis and calculation of the index of 20
                                    cryptocurrencies based on data from October 10, 2023, to June 23, 2024. The
                                    index calculation allows for effectively tracking the state and dynamics of the
                                    cryptocurrency market, providing a valuable tool for investors and analysts. The
                                    methodology used and the presented formulas ensure accuracy and reliability
                                    of the calculations, as confirmed by the significant increase in the index over
                                    the specified period.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperOEindex;
