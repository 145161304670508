import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperTMdeflationaryModel = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Deflationary Model')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Deflationary Model (Burning)</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                The OMT team deeply cares about the prosperity and development of our
                                project, which is why we have designed a deflationary model to maintain a
                                stable token value. Here are the key elements of our model:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <Fade right duration={600}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(a)</div>
                                        OMT Fund: We have created the OMT Fund specifically for purchasing
                                        tokens on exchanges. 50% of the company's overall profits are
                                        allocated to this fund. Quarterly, we use the funds accumulated in the
                                        OMT Fund to buy tokens on exchanges, which are then burned,
                                        removing them permanently from circulation;
                                    </div>
                                </Fade>
                                <Fade right duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(b)</div>
                                        Staking Commission: We collect a commission from users who stake
                                        our tokens. 50% of these commissions are automatically directed
                                        towards the burning process;
                                    </div>
                                </Fade>
                                <Fade right duration={800}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(c)</div>
                                        Internal Purchases on the OMT Platform: When users make internal
                                        purchases on the OMT platform using OMTS tokens, all the tokens
                                        received from these transactions are fully burned.
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    By implementing these measures, we aim to reduce the token supply over time,
                                    leading to a deflationary effect and potentially increasing the value of the
                                    remaining tokens. This model promotes scarcity and helps maintain stability
                                    within the OMT ecosystem.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperTMdeflationaryModel;