import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setSwapRequestModalStatus} from "../../store/cabinet/action";



const SwapMain = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const swapMainData = useSelector(store => store.cabinet.swapMainData);

    const requestHandler = () =>{
        dispatch(setSwapRequestModalStatus(true));
    }

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.contentWrapItem}`}>
                    <div className={`${Styles.titleWSF} ${Styles.t1}`}><span className={`${Styles.colorYellow} ${Styles.titleWSF}`}>P2P</span> SWAP <span className={`${Styles.typeBeta}`}>BETA</span></div>
                    <div className={`${Styles.infoWrap}`}>
                        <div className={`${Styles.infoTitle} ${Styles.titleWSF}`}>{t('Доступно для обмена')}</div>
                        <div className={`${Styles.infoValue}`}>{swapMainData?.available ? swapMainData?.available : '0'} <span>OMT20</span></div>
                    </div>
                    <div className={`${Styles.button}`} onClick={()=>{requestHandler()}}>{t('Создайте заявку')}</div>
                </div>
                <div className={`${Styles.contentWrapItem}`}>
                    <div className={`${Styles.textWrap}`}>
                        <div className={`${Styles.text}`}>
                            <span>{t('Добро пожаловать в P2P SWAP (beta)')}</span> - {t('это уникальное решение для торговли INDEX OMT20 между пользователями. Здесь можно разместить заявку на покупку или продажу индекса, указав цену и количество, или выбрать уже существующую заявку с подходящими условиями')}.
                        </div>
                        <div className={`${Styles.text}`}>
                            {t('Простой и быстрый функционал позволяет оперативно совершать сделки без лишних сложностей, делая процесс покупки и продажи удобным даже для новичков')}. <span>{t('P2P SWAP (beta) создан для лёгкого обмена Index OMT20 и гибкого управления активами')}.</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SwapMain;
