import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperExecutiveSummary = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Executive Summary')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Executive Summary</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    In this document, the Oracle Meta Technologies team has analyzed
                                    the state of the cryptocurrency market in the fourth quarter of 2022
                                    and discussed the role of OMT Technologies in the market. The
                                    document provides information about the mission, history, key
                                    technologies, and products of the project. You will learn about the
                                    strategy of maintaining the value of Oracle Meta Technologies, which
                                    the team has successfully implemented for over a year, and the
                                    project's plans for the near future.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperExecutiveSummary;