import React, {useState} from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";



const IndexPresentation = () => {
    const { t } = useTranslation();
    const [curLng, setCurLng] = useState('RU');

    const mainBtnHandler = () =>{
        window.open(`https://s3.eu-central-1.amazonaws.com/honee2.0/presentation_${curLng.toLowerCase()}.pdf`, "_blank")
    }

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.contentWrap}`}>
                    <div className={`${Styles.note}`}>
                        {t('Чтобы узнать подробнее об OMT20 INDEX скачайте и изучите презентацию на удобном для вас языке')}!
                    </div>
                    <div className={`${Styles.lngWrap}`}>
                        <div className={`${Styles.lngTitle}`}>{t('Выберите язык презентации')}:</div>
                        <div className={`${Styles.lngBox}`}>
                            <div className={`${Styles.lngBoxItem} ${curLng === 'RU' ? Styles.lngBoxItemActive : null}`} onClick={()=>{setCurLng('RU')}}>RU</div>
                            <div className={`${Styles.lngBoxItem} ${curLng === 'EN' ? Styles.lngBoxItemActive : null}`} onClick={()=>{setCurLng('EN')}}>EN</div>
                            <div className={`${Styles.lngBoxItem} ${curLng === 'DE' ? Styles.lngBoxItemActive : null}`} onClick={()=>{setCurLng('DE')}}>DE</div>
                            <div className={`${Styles.lngBoxItem} ${curLng === 'ES' ? Styles.lngBoxItemActive : null}`} onClick={()=>{setCurLng('ES')}}>ES</div>
                            <div className={`${Styles.lngBoxItem} ${curLng === 'ZH' ? Styles.lngBoxItemActive : null}`} onClick={()=>{setCurLng('ZH')}}>ZH</div>
                        </div>
                    </div>
                    <div className={`${Styles.button}`} onClick={()=>{mainBtnHandler()}}>{t('Скачать презентацию')}</div>
                </div>
            </div>
        </section>
    );
};

export default IndexPresentation;
