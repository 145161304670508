import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperOEoverview = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Overview')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Overview of the Ecosystem Products</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Oracle Meta Technologies is an ecosystem of products designed to meet the
                                    constantly evolving needs of the cryptocurrency market. Oracle offers a unique
                                    set of innovative products that provide stability and growth opportunities.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    One of the key products of Oracle Meta Technologies is a wallet for storing and
                                    exchanging cryptocurrencies. It provides a secure and reliable way to store
                                    cryptocurrency, allowing for quick and easy operations with various types of
                                    cryptocurrencies. Additionally, the Oracle wallet supports integration with
                                    exchanges, enabling users to trade without the need to transfer their funds to
                                    other platforms.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Another product that sets Oracle Meta Technologies apart from its competitors
                                    is the AI bot. The use of AI in cryptocurrency trading is becoming increasingly
                                    common with advancements in technology. Oracle offers its own AI bot capable
                                    of independently analyzing data, predicting market trends, and executing
                                    trades based on that data. The bot utilizes machine learning methods to
                                    constantly improve its efficiency and effectiveness.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The third product is staking, which allows users to earn rewards by holding
                                    their coins in the Oracle wallet. It provides a convenient way to generate
                                    income using one's cryptocurrencies without selling them. Oracle coin staking
                                    helps maintain the stability of the cryptocurrency ecosystem and enhances
                                    trust among its participants.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    In addition to these core products, Oracle offers a range of additional products
                                    that cater to various user needs and demands. For example, the Oracle
                                    platform features a crowdfunding marketplace that allows investors to support
                                    new cryptocurrency projects.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperOEoverview;