import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    getActiveFreeze,
    getActiveFreezeSuccess, getFreezeTotal,
    getIndexMainData,
    getIndexSwapHistory,
    getIndexSwapPrediction
} from "../../store/cabinet/action";
import Seo from "../../components/_UI/seo";
import IndexMain from "../../components/indexMain";
import IndexTotal from "../../components/indexTotal";
import IndexSwap from "../../components/indexSwap";
import IndexHistory from "../../components/indexHistory";
import IndexPrediction from "../../components/indexPrediction";
import IndexFreeze from "../../components/indexFreeze";
import IndexFreezeModal from "../../components/indexFreezeModal";
import IndexCounter from "../../components/indexCounter";
import IndexPresentation from "../../components/indexPresentation";


const IndexPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const activeFreeze = useSelector(store => store.cabinet.activeFreeze);
    const postLink = '/white-paper/omt-ecosystem/index-omt20';

    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getIndexMainData());
        dispatch(getIndexSwapHistory('week'));
        dispatch(getIndexSwapPrediction(360));
        dispatch(getFreezeTotal());
    },[dispatch])

    useEffect(()=>{
        if (!activeFreeze){
            dispatch(getActiveFreeze(1));
        } else if (activeFreeze.current_page + 1 <= activeFreeze.last_page && isScrolledToEnd){
            dispatch(getActiveFreeze(activeFreeze.current_page + 1));
            setIsScrolledToEnd(false);
        }

    },[dispatch, isScrolledToEnd]) // eslint-disable-line

    useEffect(() => {
        return (()=>{dispatch(getActiveFreezeSuccess(false))})
    }, [dispatch]);

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'Index'}/>
            <div className={`${Styles.contentWrap}`}>
                <IndexCounter/>
                <IndexPresentation/>
                <IndexMain link={postLink}/>
                <IndexTotal/>
                <IndexSwap/>
                <IndexHistory link={postLink}/>
                <IndexPrediction/>
                <IndexFreeze scrollAction={setIsScrolledToEnd}/>
                <IndexFreezeModal/>
            </div>
        </section>
    );
};

export default IndexPage;