import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import { useTranslation } from "react-i18next";
import {
    cancelMySwapRequest,
    clearMySwapRequestList,
    setSwapRequestCancelModalStatus
} from "../../store/cabinet/action";
import { useDispatch, useSelector } from "react-redux";
import {toast} from "react-toastify";

const SwapRequestCancelModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const swapRequestCancelModalStatus = useSelector(store => store.cabinet.swapRequestCancelModalStatus);
    const swapMainData = useSelector(store => store.cabinet.swapMainData);
    const cancelMySwapRequestsStarted = useSelector(store => store.cabinet.cancelMySwapRequestsStarted);


    const mainBtnHandler = () => {
        const successHandler = () =>{
            closeModal();
            toast('Ваша завка отменена!');
            dispatch(clearMySwapRequestList(true));
        }

        if (isRequestAvailable()){
            dispatch(cancelMySwapRequest(swapRequestCancelModalStatus?.data?.id, successHandler))
        }
    };

    const clearForm = () => {

    };

    const isCommissionSuccess = () =>{
        if (swapRequestCancelModalStatus?.data?.direction === 'buy'){
            return true
        } else {
            return Number(swapMainData?.honee?.amount) >= Number(swapMainData?.honee_commission)
        }
    }

    const isRequestAvailable = () =>{
        return isCommissionSuccess() && !cancelMySwapRequestsStarted
    }

    const closeModal = () => {
        dispatch(setSwapRequestCancelModalStatus({status: false, data: false}));
        clearForm();
    };

    return (
        <Popup
            open={swapRequestCancelModalStatus.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={closeModal}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title} ${Styles.titleWSF}`}>{t('Подтверждение отмены заявки')}</div>
                    <div
                        className={`${Styles.note}`}>{t('При отмене заявки после ее создания взымается комиссия в Honee')}</div>
                    <div className={`${Styles.commissionRow}`}>
                        <div className={`${Styles.commissionRowName}`}>{t('Комиссия')}:</div>
                        <div className={`${Styles.commissionRowValue}`}>{swapRequestCancelModalStatus?.data?.direction === 'buy' ? '0.2' : swapMainData?.honee_commission} Honee</div>
                    </div>
                    <div className={`${Styles.requestWrap}`}>
                        <div className={`${Styles.requestTitle} ${Styles.titleWSF}`}>{t('Ваша заявка')}:</div>
                        <div className={`${Styles.requestBox}`}>
                            <div className={`${Styles.requestBoxItem}`}>
                                <div className={`${Styles.requestBoxItemName}`}>{t('Тип сделки')}:</div>
                                <div className={`${Styles.requestBoxItemValue} ${swapRequestCancelModalStatus?.data?.direction === 'buy' ? Styles.requestBoxItemValueBuy : Styles.requestBoxItemValueSell}`}>{swapRequestCancelModalStatus?.data?.direction === 'buy' ? t('Купить') : t('Продать')}</div>
                            </div>
                            <div className={`${Styles.requestBoxItem}`}>
                                <div className={`${Styles.requestBoxItemName}`}>{t('Количество')} <span>OMT20</span>:
                                </div>
                                <div className={`${Styles.requestBoxItemValue}`}>{swapRequestCancelModalStatus?.data?.amount_buy * 1}</div>
                            </div>
                            <div className={`${Styles.requestBoxItem}`}>
                                <div className={`${Styles.requestBoxItemName}`}>{t('Эквивалент в')} <span>Honee</span>:
                                </div>
                                <div className={`${Styles.requestBoxItemValue}`}>{swapRequestCancelModalStatus?.data?.amount_sell * 1}</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.mainBtn} ${isRequestAvailable() ? Styles.mainBtnActive : null}`} onClick={()=>{mainBtnHandler()}}>{t('Подтвердить')}</div>
                </div>
            </div>
        </Popup>
    );
};

export default SwapRequestCancelModal;
