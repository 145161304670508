import React, {useEffect, useRef, useState} from "react";
import Styles from './styles.module.scss'
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {walletShorter} from "../../helpers/walletShorter";
import {clearMySwapRequestList, getMySwapRequests, setSwapRequestCancelModalStatus} from "../../store/cabinet/action";



const SwapRequest = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const scrollRef = useRef(null);

    const mySwapRequests = useSelector(store => store.cabinet.mySwapRequests);
    const clearMySwapRequestListStatus = useSelector(store => store.cabinet.clearMySwapRequestListStatus);


    const [list, setList] = useState([]);

    const canceledHandler = (item) => {
        dispatch(setSwapRequestCancelModalStatus({status: true, data: item}))
    }

    const statusContentHandler = (status) => {
        const formatStatus = status.length > 1 ? (status[0].toUpperCase() + status.slice(1)).replace(/_/g, ' ') : false
        switch (status) {
            case 'confirmed':
                return t('Подтвержден');
            case 'unconfirmed':
                return t('Отменен');
            case 'unconfirmed_manually':
                return t('В обработке');
            case 'canceled':
                return t('Отменен');
            case 'created':
                return t('Активен');
            case 'pending':
                return t('В обработке');
            case 'pending_for_cancellation':
                return t('В обработке');
            case 'pending_for_cancellation_unconfirmed':
                return t('В обработке');
            default:
                return formatStatus ? formatStatus : status
        }
    }

    // useEffect(() => {
    //     setList([...list, ...(mySwapRequests.data || [])] || []);
    // }, [mySwapRequests]) // eslint-disable-line

    useEffect(() => {
        if (clearMySwapRequestListStatus) {
            setList([]);
            dispatch(clearMySwapRequestList(false));
            dispatch(getMySwapRequests(1));
        }
    }, [clearMySwapRequestListStatus, dispatch])

    useEffect(() => {
        if (list?.length > 0 && mySwapRequests?.data?.length > 0) {
            let isDuplicate = false;
            list.forEach((item) => {
                if (item.id === mySwapRequests.data[mySwapRequests.data.length - 1].id) {
                    isDuplicate = true;
                }
            })
            if (!isDuplicate) {
                setList([...list, ...(mySwapRequests.data || [])] || []);
            }
        } else {
            setList([...list, ...(mySwapRequests.data || [])] || []);
        }

    }, [mySwapRequests]) // eslint-disable-line

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.title3} ${Styles.titleWSF}`}>{t('ВАШИ Заявки')}</div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Тип сделки')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Количество')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Эквивалент в')} Honee</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Адрес кошелька')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Действие')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`} ref={scrollRef}>
                        {list.length ? list.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem} ${item.status === 'confirmed' || item.status === 'canceled' ? Styles.contentBoxItemGray : null}`} key={'swapRequest' + index}>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Тип сделки')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue} ${item.direction === 'buy' ? Styles.contentBoxItemWrapValueGreen : Styles.contentBoxItemWrapValueRed}`}>{item.direction === 'buy' ? t('Купить') : t('Продать')}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Количество')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>{item.direction === 'buy' ? item.amount_buy * 1 : item.amount_sell * 1}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Эквивалент в')} Honee</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>{item.direction === 'buy' ? item.amount_sell * 1 : item.amount_buy * 1}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Адрес кошелька')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue} ${Styles.contentBoxItemWrapValueWhite}`}>{walletShorter(item.address)}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue} ${Styles.contentBoxItemWrapValueWhite}`}>{statusContentHandler(item.status)}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Действие')}</div>
                                        <div
                                            className={`${Styles.contentBoxItemWrapValue} ${item.status === 'created' ? Styles.contentBoxItemWrapValueRed : Styles.contentBoxItemWrapValueGray} ${item.status === 'created' ? Styles.pointer : null}`}
                                            onClick={()=>{
                                                if (item.status === 'created'){
                                                    canceledHandler(item);
                                                }
                                            }}
                                        >
                                            {t('Отменить')}
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : <EmptyValues text={t('У вас еще не создано ни одной заявки')} withIcon={false} withBtn={false}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SwapRequest;
