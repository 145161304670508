import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import { useTranslation } from "react-i18next";
import {
    clearMySwapRequestList,
    createBuySwapRequest,
    createBuySwapRequestSuccess, createSellSwapRequest, createSellSwapRequestSuccess, getMySwapRequests,
    getSwapCommission,
    getSwapCommissionSuccess,
    setSwapRequestModalStatus
} from "../../store/cabinet/action";
import { useDispatch, useSelector } from "react-redux";
import CryptoIcon from "../_UI/cryptoIcon";
import {walletShorter} from "../../helpers/walletShorter";
import {toast} from "react-toastify";

const SwapRequestModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const swapRequestModalStatus = useSelector(store => store.cabinet.swapRequestModalStatus);
    const swapMainData = useSelector(store => store.cabinet.swapMainData);
    const swapCommission = useSelector(store => store.cabinet.swapCommission);
    const createBuySwapRequestStatusStarted = useSelector(store => store.cabinet.createBuySwapRequestStatusStarted);
    const createSellSwapRequestStatusStarted = useSelector(store => store.cabinet.createSellSwapRequestStatusStarted);





    const [isBuyType, setIsBuyType] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [honee, setHonee] = useState('');
    const [omt, setOmt] = useState('');

    const isRequestAvailable = () =>{
        if (isBuyType){
            return Number(swapMainData?.honee?.amount) >= Number(honee) && honee !== '' && honee > 0 && isCommissionSuccess() && !createBuySwapRequestStatusStarted
        } else {
            return Number(swapMainData?.omt20?.amount) >= Number(omt) && omt !== '' && omt > 0 && isCommissionSuccess() && !createSellSwapRequestStatusStarted
        }
    }

    const mainBtnHandler = () => {
        if (isRequestAvailable()){
            setCurrentStep(2);
        }
    };

    const acceptHandler = () => {
        const successHandler = () =>{
            closeModal();
            toast(t('Ваша заявка создана, её статус вы можете отследить на странице заявок!'));
            dispatch(clearMySwapRequestList(true));
            dispatch(getMySwapRequests(1));
        }

        if (isBuyType){
            if (isRequestAvailable()){
                dispatch(createBuySwapRequest(honee, successHandler));
            }
        } else {
            if (isRequestAvailable()){
                dispatch(createSellSwapRequest(omt, successHandler));
            }
        }
    };

    const typeHandler = (type) =>{
        setIsBuyType(type);
        clearForm();
    }

    const clearForm = () => {
        setHonee('');
        setOmt('');
        setCurrentStep(1);
        dispatch(getSwapCommissionSuccess(false));
        dispatch(createBuySwapRequestSuccess(false));
        dispatch(createSellSwapRequestSuccess(false));
    };

    const closeModal = () => {
        dispatch(setSwapRequestModalStatus(false));
        clearForm();
    };

    const isCommissionSuccess = () =>{
        if (isBuyType){
            return Number(swapMainData?.honee?.amount) >= swapMainData?.honee_commission
        } else {
            return Number(swapCommission?.wallet?.amount) >= swapCommission?.commission
        }

    }

    const handleInputChange = (value, isHoneeInput) => {
        const honeeRate = Number(swapMainData.honee_currency.rate);
        const omtRate = Number(swapMainData.omt20_currency.rate);

        if (isHoneeInput) {
            setHonee(value);
            setOmt(value ? (Number(value) * honeeRate / omtRate).toFixed(4) * 1 : '');
        } else {
            setOmt(value);
            setHonee(value ? (Number(value) * omtRate / honeeRate).toFixed(4) * 1 : '');
        }
    };

    useEffect(() => {
        if (!isBuyType && omt !== '' && omt > 0){
            dispatch(getSwapCommission(omt))
        }
    }, [isBuyType, omt, dispatch]);


    return (
        <Popup
            open={swapRequestModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={closeModal}>
                    <div />
                    <div />
                </div>
                {currentStep === 1 ?
                    <div className={`${Styles.modalContentWrap}`}>
                        <div className={`${Styles.title}`}>{t('Создание заявки')}</div>
                        <div className={`${Styles.typeRow}`}>
                            <div className={`${Styles.typeRowName}`}>{t('Выберите тип сделки')}:</div>
                            <div className={`${Styles.typeRowBtnsWrap}`}>
                                <div
                                    className={`${Styles.typeRowBtnsItem} ${isBuyType ? null : Styles.typeRowBtnsItemActive}`}
                                    onClick={() => {typeHandler(false)}}
                                >
                                    {t('Продать')}
                                </div>
                                <div
                                    className={`${Styles.typeRowBtnsItem} ${isBuyType ? Styles.typeRowBtnsItemActive : null}`}
                                    onClick={() => {typeHandler(true)}}
                                >
                                    {t('Купить')}
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.modalContentBox}`}>
                            <div className={`${Styles.inputWrap}`}>
                                <div className={`${Styles.inputBox}`}>
                                    <div className={`${Styles.inputBoxTitle}`}>{t('Введите количество')} <span>{isBuyType ? 'Honee' : 'OMT20'}</span></div>
                                    <input
                                        type="number"
                                        value={isBuyType ? honee : omt}
                                        onChange={(e) => handleInputChange(e.target.value, isBuyType)}
                                    />
                                    <div className={`${Styles.inputBoxAvailable}`}>
                                        {t('Доступно')}: <span>{isBuyType ? swapMainData?.honee?.amount * 1 || '0' : swapMainData?.omt20?.amount * 1 || '0'} {isBuyType ? 'Honee' : 'OMT20'}</span>
                                    </div>
                                    <div className={`${Styles.inputBoxAbsCryptoIcon}`}>{isBuyType ? <CryptoIcon short_name={'HONEE'}/> : <CryptoIcon short_name={'OMT20'}/>}</div>
                                    <div className={`${Styles.inputBoxAbsText}`}>{isBuyType ? 'Honee' : 'OMT20'}</div>
                                </div>
                                <div className={`${Styles.inputBox}`}>
                                    <div className={`${Styles.inputBoxTitle}`}>{t('Вы получите')} <span>{isBuyType ? 'OMT20' : 'Honee'}</span></div>
                                    <input
                                        type="number"
                                        value={isBuyType ? omt : honee}
                                        onChange={(e) => handleInputChange(e.target.value, !isBuyType)}
                                    />
                                    <div className={`${Styles.inputBoxAvailable}`}>
                                        {t('Доступно')}: <span>{isBuyType ? swapMainData?.omt20?.amount * 1 || '0' : swapMainData?.honee?.amount * 1 || '0'} {isBuyType ? 'OMT20' : 'Honee'}</span>
                                    </div>
                                    <div className={`${Styles.inputBoxAbsCryptoIcon}`}>
                                        {isBuyType ? <CryptoIcon short_name={'OMT20'}/> : <CryptoIcon short_name={'HONEE'}/>}
                                    </div>
                                    <div className={`${Styles.inputBoxAbsText}`}>{isBuyType ? 'OMT20' : 'Honee'}</div>
                                </div>
                            </div>
                            <div className={`${Styles.commissionRow}`}>
                                <div className={`${Styles.commissionRowName}`}>{t('Комиссия')}:</div>
                                {isBuyType ?
                                    <div className={`${Styles.commissionRowValue}`}>
                                        {swapMainData.honee_commission} Honee {isCommissionSuccess() ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span>}
                                    </div> :
                                    <div className={`${Styles.commissionRowValue}`}>
                                        <span>{swapCommission?.commission || swapCommission?.commission === 0 ? `${Number(swapCommission?.commission)} ${swapCommission?.currency?.short_name}` : omt !== '' && omt > 0 ? t('Загрузка...') : t('Введите сумму')} {swapCommission?.commission ? isCommissionSuccess() ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span> : null}</span>
                                    </div>
                                }
                            </div>
                            <div className={`${Styles.note}`}>{t('При отмене заявки после ее создания взымается комиссия в')} Honee</div>
                            <div className={`${Styles.mainBtn} ${isRequestAvailable() ? Styles.mainBtnActive : null}`} onClick={mainBtnHandler}>{t('Создать заявку')}</div>
                        </div>
                    </div>
                    : null}
                {currentStep === 2 ?
                    <div className={`${Styles.modalContentWrap} ${Styles.modalContentWrapReposition}`}>
                        <div className={`${Styles.title} ${Styles.titleWSF}`}>{t('Подтверждение продажи')}</div>
                        <div className={`${Styles.modalContentWrapRepositionBox}`}>
                            <div className={`${Styles.modalContentWrapItem}`}>
                                <div className={`${Styles.modalContentWrapItemName}`}>{t('Тип сделки')}:</div>
                                <div
                                    className={`${Styles.modalContentWrapItemValue} ${isBuyType ? Styles.modalContentWrapItemValueBuy : Styles.modalContentWrapItemValueSell}`}>{isBuyType ? t('Купить') : t('Продать')}</div>
                            </div>
                            <div className={`${Styles.modalContentWrapItem}`}>
                                <div className={`${Styles.modalContentWrapItemName}`}>{t('Количество')} <span>OMT20</span>:</div>
                                <div className={`${Styles.modalContentWrapItemValue}`}>{omt}</div>
                            </div>
                            <div className={`${Styles.modalContentWrapItem}`}>
                                <div className={`${Styles.modalContentWrapItemName}`}>{t('Эквивалент в')} <span>Honee</span>:</div>
                                <div className={`${Styles.modalContentWrapItemValue}`}>{honee}</div>
                            </div>
                            <div className={`${Styles.modalContentWrapItem}`}>
                                <div className={`${Styles.modalContentWrapItemName}`}>{t('Адрес кошелька')}:</div>
                                <div
                                    className={`${Styles.modalContentWrapItemValue} ${Styles.modalContentWrapItemValueWallet}`}>{walletShorter(swapMainData?.omt20?.address)}</div>
                            </div>
                            <div className={`${Styles.modalContentWrapItem}`}>
                                <div className={`${Styles.modalContentWrapItemName}`}>{t('Комиссия')}:</div>
                                {isBuyType ?
                                    <div className={`${Styles.modalContentWrapItemValue}`}>
                                        {swapMainData.honee_commission} Honee {isCommissionSuccess() ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span>}
                                    </div> :
                                    <div className={`${Styles.modalContentWrapItemValue}`}>
                                        <span>{swapCommission?.commission || swapCommission?.commission === 0 ? `${Number(swapCommission?.commission)} ${swapCommission?.currency?.short_name}` : omt !== '' && omt > 0 ? t('Загрузка...') : t('Введите сумму')} {swapCommission?.commission ? isCommissionSuccess() ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span> : null}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={`${Styles.mainBtn} ${isRequestAvailable() ? Styles.mainBtnActive : null}`} onClick={()=>{acceptHandler()}}>{t('Подтвердить')}</div>
                    </div>
                    : null}
            </div>
        </Popup>
    );
};

export default SwapRequestModal;
