import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperMAdevelopmentTrends = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Development Trends')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Market Development Trends</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The crypto market in 2021 continues to show growth and development. One of the main
                                    factors distinguishing this period from the previous crypto winter is the increase
                                    in the number of cryptocurrency holders. This indicates that more and more people are
                                    starting to trust digital assets and consider them as a means of investment and
                                    protection against inflation.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Another factor that continues to support interest in cryptocurrencies is the constant
                                    attention from prominent figures, such as Elon Musk. Institutional investors, such as
                                    major investment funds and banks, have been investing more actively in the crypto market.
                                    This, in turn, brings cryptocurrencies closer to the level of traditional financial
                                    instruments and gives greater confidence in their stability and reliability. In addition,
                                    such large investors can inject significant capital into the market and contribute to its growth.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    However, despite some volatility, the crypto market continues to show growth, and new technologies
                                    such as blockchain and decentralized applications continue to evolve and improve. This opens up new
                                    opportunities for innovation and the creation of new business models based on digital assets.
                                    In addition, new rules and regulations introduced in various countries can make the crypto market
                                    more transparent and stable. This could lead to a decrease in volatility and an increase in investor
                                    confidence in cryptocurrencies. This time, not only private traders will return assets to historical highs.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    New rules of the game will make the market more transparent and stable. This, in turn, will attract
                                    new capital that can serve as "fuel" for growth.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperMAdevelopmentTrends;