import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperHAparabolicSar = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Parabolic SAR')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Parabolic SAR</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/trading/introduction-to-parabolic-sar/", "_blank")}}>parabolic SAR</span> indicator, developed by J. Wells Wilder, is used by traders to
                                    determine trend direction and potential reversals in price. The indicator uses a
                                    trailing stop and reverse method called "SAR," or stop and reverse, to identify
                                    suitable exit and <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/e/entry-point.asp", "_blank")}}>entry points</span>. Traders also refer to the indicator as to the
                                    parabolic stop and reverse, parabolic SAR, or PSAR.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The parabolic SAR indicator appears on a chart as a series of dots, either above
                                    or below an asset's price, depending on the direction the price is moving. A dot
                                    is placed below the price when it is <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/t/trending-market.asp", "_blank")}}>trending</span> upward, and above the price when
                                    it is trending downward.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.title2}`}>The Formula for the Parabolic SAR Indicator</div>
                                <div className={`${Styles.paragraph}`}>
                                    A rising PSAR has a slightly different formula than a falling PSAR.
                                </div>
                                <div className={`${Styles.paragraph}`}>
                                    <span className={Styles.colorOrange}>RPSAR</span> = Prior PSAR + [Prior AF(Prior EP-Prior PSAR)]<br/>
                                    <span className={Styles.colorOrange}>FPSAR</span> = Prior PSAR − [Prior AF(Prior PSAR-Prior EP)]<br/>
                                    where:<br/>
                                    <span className={Styles.colorOrange}>RPSAR</span> = Rising PSAR<br/>
                                    <span className={Styles.colorOrange}>AF</span> = Acceleration Factor, it starts at 0.02 and
                                    increases by 0.02, up to a maximum of 0.2, each
                                    time the extreme point makes a new low (falling
                                    SAR) or high(rising SAR)<br/>
                                    <span className={Styles.colorOrange}>FPSAR</span> = Falling PSAR<br/>
                                    <span className={Styles.colorOrange}>EP</span> = Extreme Point, the lowest low in the current
                                    downtrend(falling SAR)or the highest high in the
                                    current uptrend(rising SAR)
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    The PSAR moves regardless of whether the price moves. This means that if the
                                    price is rising initially, but then moves sideways, the PSAR will keep rising
                                    despite the sideways movement in price. A <span className={`${Styles.link}`} onClick={()=>{window.open("https://www.investopedia.com/terms/r/reversal.asp", "_blank")}}>reversal</span> signal will be generated at
                                    some point, even if the price hasn't dropped. The PSAR only needs to catch up
                                    to price to generate a reversal signal. For this reason, a reversal signal on the
                                    indicator doesn't necessarily mean the price is reversing.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperHAparabolicSar;