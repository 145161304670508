import React, {useEffect, useRef, useState} from "react";
import Styles from './styles.module.scss'
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {walletShorter} from "../../helpers/walletShorter";
import {
    clearAllSwapRequestList, getAllSwapRequests, setSwapRequestConfirmModalStatus
} from "../../store/cabinet/action";



const SwapRequestAll = (props) => {
    const {currentFilter, setCurrentFilter} = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const scrollRef = useRef(null);

    const allSwapRequests = useSelector(store => store.cabinet.allSwapRequests);
    const clearAllSwapRequestListStatus = useSelector(store => store.cabinet.clearAllSwapRequestListStatus);
    const swapMainData = useSelector(store => store.cabinet.swapMainData);


    const [list, setList] = useState([]);

    // const canceledHandler = (item) =>{
    //     dispatch(setSwapRequestCancelModalStatus({status: true, data: item}))
    // }

    const statusContentHandler = (status) =>{
        const formatStatus = status.length > 1 ? (status[0].toUpperCase() + status.slice(1)).replace(/_/g, ' ') : false
        switch (status){
            case 'confirmed':
                return t('Подтвержден');
            case 'unconfirmed':
                return t('В обработке');
            case 'unconfirmed_manually':
                return t('В обработке');
            case 'canceled':
                return t('Отменен');
            case 'created':
                return t('Активен');
            case 'pending':
                return t('В обработке');
            case 'pending_for_cancellation':
                return t('В обработке');
            default:
                return formatStatus ? formatStatus : status
        }
    }

    const isActionAvailable = (item) =>{
        if (item.available) {
            if (item.status !== 'created') {
                return false
            } else if (item.direction === 'buy') {
                return Number(swapMainData?.omt20?.amount) >= Number(item.amount_buy)
            } else {
                return Number(swapMainData?.honee?.amount) >= Number(item.amount_buy)
            }
        } else {
            return false
        }
    }

    // useEffect(()=>{
    //     setList([...list, ...(allSwapRequests.data || [])] || []);
    // },[allSwapRequests]) // eslint-disable-line

    useEffect(()=>{
        if (clearAllSwapRequestListStatus){
            setList([]);
            dispatch(clearAllSwapRequestList(false));
            dispatch(getAllSwapRequests(1, currentFilter));
        }
    },[clearAllSwapRequestListStatus, dispatch]) // eslint-disable-line

    useEffect(() => {
        if (list?.length > 0 && allSwapRequests?.data?.length > 0) {
            let isDuplicate = false;
            list.forEach((item) => {
                if (item.id === allSwapRequests.data[allSwapRequests.data.length - 1].id) {
                    isDuplicate = true;
                }
            })
            if (!isDuplicate) {
                setList([...list, ...(allSwapRequests.data || [])] || []);
            }
        } else {
            setList([...list, ...(allSwapRequests.data || [])] || []);
        }

    }, [allSwapRequests]) // eslint-disable-line

    return (
        <section className={`${Styles.positionWrap}`}>
            <div className={`${Styles.filterWrap}`}>
                <div className={`${Styles.filterTitle}`}>{t('Фильтр')}:</div>
                <div className={`${Styles.filterBox}`}>
                    <div className={`${Styles.filterBoxItem} ${currentFilter === 'all' || !currentFilter ? Styles.filterBoxItemActive : null}`} onClick={()=>{setCurrentFilter('all')}}>{t('Все')}</div>
                    <div className={`${Styles.filterBoxItem} ${currentFilter === 'buy' ? Styles.filterBoxItemActive : null}`} onClick={()=>{setCurrentFilter('buy')}}>{t('Купить')}</div>
                    <div className={`${Styles.filterBoxItem} ${currentFilter === 'sell' ? Styles.filterBoxItemActive : null}`} onClick={()=>{setCurrentFilter('sell')}}>{t('Продать')}</div>
                </div>
            </div>
            <div className={`${Styles.container} ${Styles.honeeCard}`}>
                <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                    <div className={`${Styles.cardBodyWithScroll}`}>
                        <div className={`${Styles.title3} ${Styles.titleWSF}`}>{t('Заявки')}</div>
                        <div className={`${Styles.titleRow}`}>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Тип сделки')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Количество')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Эквивалент в')} Honee</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Адрес кошелька')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Действие')}</div>
                        </div>
                        <div className={`${Styles.contentBox}`} ref={scrollRef}>
                            {list.length ? list.map((item, index) => {
                                return (
                                    <div className={`${Styles.contentBoxItem} ${item.available ? null : Styles.contentBoxItemGray}`} key={'swapRequestAll' + index}>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Тип сделки')}</div>
                                            <div className={`${Styles.contentBoxItemWrapValue} ${item.direction === 'buy' ? Styles.contentBoxItemWrapValueGreen : Styles.contentBoxItemWrapValueRed}`}>{item.direction === 'buy' ? t('Купить') : t('Продать')}</div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Количество')}</div>
                                            <div className={`${Styles.contentBoxItemWrapValue}`}>{item.direction === 'buy' ? item.amount_buy * 1 : item.amount_sell * 1}</div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Эквивалент в')} Honee</div>
                                            <div className={`${Styles.contentBoxItemWrapValue}`}>{item.direction === 'buy' ? item.amount_sell * 1 : item.amount_buy * 1}</div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Адрес кошелька')}</div>
                                            <div className={`${Styles.contentBoxItemWrapValue} ${Styles.contentBoxItemWrapValueWhite}`}>{walletShorter(item.address)}</div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                                            <div className={`${Styles.contentBoxItemWrapValue} ${Styles.contentBoxItemWrapValueWhite}`}>{statusContentHandler(item.status)}</div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Действие')}</div>
                                            <div
                                                className={`${Styles.contentBoxItemWrapValue} ${isActionAvailable(item) ? item.direction === 'buy' ? Styles.contentBoxItemWrapValueRed : Styles.contentBoxItemWrapValueGreen : Styles.contentBoxItemWrapValueGray} ${item.status === 'created' ? Styles.pointer : null}`}
                                                onClick={() => {
                                                    if (isActionAvailable(item)) {
                                                        dispatch(setSwapRequestConfirmModalStatus({status: true, data: item, currentFilter: currentFilter}))
                                                    }
                                                }}
                                            >
                                                {item.direction === 'buy' ? t('Продать') : t('Купить')}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : <EmptyValues text={t('На данный момент нет доступных заявок')} withIcon={false} withBtn={false}/>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SwapRequestAll;
