import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperMAadvantages = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Advantages')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Advantages of Cryptocurrencies as a Payment Method</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                Cryptocurrencies, as a means of payment, represent an innovation in the financial sphere and have a number of advantages:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <Fade right duration={600}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(a)</div>
                                        Decentralization: Unlike traditional currencies, cryptocurrencies are not controlled by central banks. This means they
                                        are not subject to inflation or deflation that a central bank may cause by increasing the money supply;
                                    </div>
                                </Fade>
                                <Fade right duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(b)</div>
                                        Security and transparency: Thanks to blockchain technology, all cryptocurrency transactions are public and traceable,
                                        as well as protected from fraud;
                                    </div>
                                </Fade>
                                <Fade right duration={800}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(c)</div>
                                        Speed and accessibility: Cryptocurrency transactions can be conducted faster than traditional banking operations,
                                        especially for international transfers. In addition, cryptocurrencies are available where there is no access to
                                        traditional banking services;
                                    </div>
                                </Fade>
                                <Fade right duration={900}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(d)</div>
                                        Low fees: Cryptocurrency transactions are typically cheaper than payments through banks or payment systems,
                                        especially for international transfers;
                                    </div>
                                </Fade>
                                <Fade right duration={1000}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(e)</div>
                                        Personal anonymity and confidentiality: While all cryptocurrency transactions are open for viewing in the public
                                        blockchain, the identities of participants remain anonymous. This can be valuable to those who wish to keep their
                                        financial affairs secret. Potential investment value: Some cryptocurrencies have significantly increased in price
                                        since their creation. Although this also means a risk of loss, many see cryptocurrencies as an investment opportunity.
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperMAadvantages;