import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperMarketChallenges = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Market Challenges')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Market Challenges</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    When entering the cryptocurrency market, projects face several difficulties
                                    such as the lack of regulatory frameworks in some countries, high hacker
                                    activity, low public trust in cryptocurrencies, and extremely high volatility. The
                                    Oracle Meta Technologies team successfully overcomes these challenges.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    For instance, legal experts thoroughly study the legislation of countries where
                                    the project operates. Developers constantly enhance the security of their
                                    products to protect against potential attacks. The Oracle Meta Technologies
                                    team actively informs users about precautionary measures to safeguard their
                                    personal data and wallets.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    To ensure stability during bear markets, Oracle Meta Technologies employs the
                                    deflationary strategy outlined in section 6 of the document. The marketing
                                    team works on raising public awareness about cryptocurrencies by creating
                                    educational materials and organizing online and offline events. As a result, the
                                    Oracle Meta Technologies team tackles all challenges associated with the
                                    cryptocurrency industry and effectively promotes its product in the market.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperMarketChallenges;