import React, {useEffect, useRef, useState} from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import Moment from "react-moment";

import exchangeIcon from '../../theme/assets/svg/h2_exchange_icon.svg';

const ExchangeHistory = (props) => {
    const {scrollAction} = props;

    const { t } = useTranslation();
    const scrollRef = useRef(null);

    const exchangeHistory = useSelector(store => store.cabinet.exchangeHistory);
    const [list, setList] = useState([]);



    const statusContentHandler = (status) =>{
        switch (status){
            case 'confirmed':
                return t('Подтвержден');
            case 'unconfirmed':
                return t('Не подтвержден');
            case 'canceled':
                return t('Отменен');
            case 'created':
                return t('Создан');
            case 'out_of_energy':
                return t('Out_of_energy');
            default:
                return t('Неизвестно')
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
                if (scrollTop + clientHeight >= scrollHeight) {
                    scrollAction(true);
                } else {
                    scrollAction(false);
                }
            }
        };

        const currentRef = scrollRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollAction]);

    useEffect(()=>{
        setList([...list, ...(exchangeHistory.data || [])] || []);
    },[exchangeHistory]) // eslint-disable-line

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.cardBodyWithScroll}`}>
                    <div className={`${Styles.titleWSF}`}>{t('История обмена')}</div>
                    <div className={`${Styles.titleRow}`}>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Тип операции')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Дата и время')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Пара')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Курс')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                        <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Количество')}</div>
                    </div>
                    <div className={`${Styles.contentBox}`} ref={scrollRef}>
                        {list.length ? list.map((item, index)=>{
                            return (
                                <div className={`${Styles.contentBoxItem}`} key={'exchangeHistory' + index}>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Тип операции')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>
                                            <div className={`${Styles.contentBoxItemNameWrap}`}>
                                                <div className={`${Styles.contentBoxItemNameImgWrap}`}>
                                                    <img src={exchangeIcon} alt="icon"/>
                                                </div>
                                                <div className={`${Styles.contentBoxItemNameBox} ${Styles.colorYellow}`}>
                                                    {t('Обмен')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div
                                            className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Дата и время')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}><Moment format="DD/MM/YYYY HH:mm">{item.date}</Moment></div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div
                                            className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Пара')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>{item.currency_from.short_name} - {item.currency_to.short_name}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Курс')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>1 {item.currency_from.short_name} = {Number(item.currency_from.rate)} {item.currency_to.short_name}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Статус')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue}`}>{statusContentHandler(item.status)}</div>
                                    </div>
                                    <div className={`${Styles.contentBoxItemWrap}`}>
                                        <div
                                            className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Количество')}</div>
                                        <div className={`${Styles.contentBoxItemWrapValue} ${Styles.colorYellow}`}>{item.amount_to * 1} {item.currency_to.short_name}</div>
                                    </div>
                                </div>
                            )
                        }) : <EmptyValues text={t('Вы еще не совершали обменов')} withIcon={false} withBtn={false}/>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExchangeHistory;
