import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperOEstakingTPcrypto = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Staking of Third-Party Cryptocurrencies')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Staking of Third-Party Cryptocurrencies</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    We serve as a reliable network validator, ensuring the security and preservation
                                    of your funds sent for staking.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Our Staking system is designed in a way that eliminates the need for wallet
                                    users to perform multiple actions such as installing special software and
                                    purchasing assets (depending on the specific blockchain system requirements).
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    You simply need to select us as a validator and send the minimum required
                                    amount for staking (according to the blockchain system's conditions) to start
                                    earning rewards based on the chosen lock-up period for your asset. In this
                                    process, you are delegating your assets to our management.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    We employ Fixed-Rate Staking (the rate may vary depending on the chosen
                                    blockchain system). Fixed-Rate Staking operates on the Lock-Up Staking
                                    principle.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Lock-Up Staking is a system that freezes your asset for a specific duration,
                                    which you can choose yourself.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    As an experienced validator, we know how to ensure the reliable and secure
                                    operation of your assets. We possess the necessary expertise and knowledge
                                    to perform our functions in the blockchain network at a high level. Additionally,
                                    we have the required technical equipment to ensure stable operation.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperOEstakingTPcrypto;