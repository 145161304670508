import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperGrowthStrategy = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Growth Strategy')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Growth Strategy of Oracle Meta Technologies</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    At the ideation stage of the Oracle Meta Technologies project, the development
                                    team devised a strategy for continuous cryptocurrency growth, incorporating
                                    the latest solutions from the crypto market.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                The strategy includes the following directions:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <Fade right duration={600}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(a)</div>
                                        Automatic liquidity increment process: Oracle utilizes an innovative
                                        technology for automatically adding liquidity to the network. This
                                        helps maintain price stability even in highly volatile market conditions;
                                    </div>
                                </Fade>
                                <Fade right duration={650}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(b)</div>
                                        Utilization of decentralization mechanisms: The Oracle cryptocurrency
                                        is fully decentralized and independent of centralized organizational
                                        decisions. This ensures transaction reliability and security;
                                    </div>
                                </Fade>
                                <Fade right duration={700}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(c)</div>
                                        Implementation of cutting-edge encryption technologies: Oracle
                                        employs state-of-the-art encryption technologies, providing a high
                                        level of transaction security. This makes Oracle one of the most
                                        secure cryptocurrencies in the market;
                                    </div>
                                </Fade>

                                <Fade right duration={750}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(d)</div>
                                        Active development of the ecosystem: The Oracle team actively
                                        expands the project's ecosystem by adding new products and
                                        functionalities that enable users to incorporate the cryptocurrency
                                        into their daily lives. This attracts more users to Oracle and facilitates
                                        faster development compared to other cryptocurrencies in the market;
                                    </div>
                                </Fade>
                                <Fade right duration={800}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(e)</div>
                                        Development of burning mechanisms: Oracle utilizes burning
                                        mechanisms to reduce the number of available coins in the market,
                                        maintaining a deflationary nature for the cryptocurrency;
                                    </div>
                                </Fade>
                                <Fade right duration={850}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(g)</div>
                                        Oracle Meta Technologies Community;
                                    </div>
                                </Fade>
                                <Fade right duration={900}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(h)</div>
                                        The Oracle community is a key factor in the cryptocurrency's growth
                                        and a competitive advantage for the project. The OMT Team places
                                        great importance on developing its community from the project's
                                        inception;
                                    </div>
                                </Fade>
                                <Fade right duration={950}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(i)</div>
                                        Currently, the number of Oracle product users exceeds one million,
                                        with each user being part of a global crypto community. Community
                                        members engage with various Oracle products such as AI, wallets, AI-based bots, and staking, thereby increasing demand for the Oracle
                                        cryptocurrency;
                                    </div>
                                </Fade>
                                <Fade right duration={1000}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(j)</div>
                                        The community's activity directly influences the growth of the Oracle
                                        price since all actions require the use of the Oracle cryptocurrency.
                                        These factors contribute favorably to the growth of the Oracle Meta
                                        Technologies project, as each Oracle transaction increases demand
                                        for the cryptocurrency while simultaneously reducing its supply in the
                                        market;
                                    </div>
                                </Fade>
                                <Fade right duration={1050}>
                                    <div className={`${Styles.paragraphListItem}`}>
                                        <div className={`${Styles.absMarker}`}>(k)</div>
                                        Oracle has one of the largest and most multicultural crypto
                                        communities in the market. All participants can engage in events and
                                        educational programs organized by the Oracle team, fostering a
                                        strong and loyal community. The Oracle team places significant
                                        importance on community development, considering it one of the main
                                        drivers of the project's growth.
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperGrowthStrategy;