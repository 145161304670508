export const dotsHandler = (x, type) =>{
    var parts = (x * 1).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, type ? "." : " ");
    return parts.join(".");
}

export const spaceHandler = (x, type) =>{
    var parts = (x * 1).toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, type ? " " : " ");
    return parts.join(".");
}