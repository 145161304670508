import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperMAforecast = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Forecast')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Forecast for the Next 5 Years</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    The accuracy of predicting cryptocurrency market dynamics depends on many
                                    factors that can influence the market in the future, some of which may be
                                    unexpected and unpredictable. However, the observed trend over the past few
                                    years has generally been positive, indicating the potential of this market.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Many people have already recognized the potential of the cryptocurrency
                                    market and have been able to profit from it, but there are still those who have
                                    doubts in this area. However, over time, like in any other field of new
                                    technologies, these doubts may dissipate.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    According to Ric Edelman, cryptocurrencies provide a unique opportunity to
                                    create wealth that has not existed for 35 years. At the same time, more
                                    conservative representatives of the financial system give more moderate
                                    forecasts for the near future, believing that by 2023, digital assets will
                                    approach previous highs, and the price of Bitcoin will be around $50,000. Some
                                    experts expect that by 2025, the price of Bitcoin will reach $100,000.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    Recently, there has been significant price volatility in the cryptocurrency
                                    market, which concerns investors. However, this is a normal phenomenon in the
                                    market of new technologies, where weak participants are being weeded out.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    Despite the fact that cryptocurrency has been around for a long time, it is still a
                                    relatively new area that is evolving and changing along with technological
                                    progress. This can create certain risks for investors, but it also provides
                                    opportunities for high returns with the right approach.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperMAforecast;