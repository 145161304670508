import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import EmptyValues from "../_UI/emtyValues";
import {useTranslation} from "react-i18next";
import {getRefLogs} from "../../store/cabinet/action";
import Moment from "react-moment";


const BonusesProfit = () => {
    const refMainData = useSelector(store => store.cabinet.refMainData);
    const refLogsCorrectData = useSelector(store => store.cabinet.refLogsCorrectData);
    const refLogsPageData = useSelector(store => store.cabinet.refLogsPageData);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [currentTableContent, setCurrentTableContent] = useState('all');

    const onScroll = (e) =>{
        if (e.target.scrollTop - (e.target.scrollHeight - e.target.clientHeight) >= -1 && e.target.scrollTop - (e.target.scrollHeight - e.target.clientHeight) <= 1){
            if (refLogsPageData?.current_page + 1 <= refLogsPageData?.last_page){
                dispatch(getRefLogs(currentTableContent, refLogsPageData?.current_page + 1))
            }
        }
    }

    const originHandler = () =>{
        switch (currentTableContent){
            case 'all':
                return {name: t('Приглашения'), emptyText: t('У вас пока что рефералов прошедших процедуру регистрации')};
            case 'bot':
                return {name: t('Бот'), emptyText: t('У вас пока что рефералов торгующих с помощью бота')};
            case 'staking':
                return {name: t('Стейкинг'), emptyText: t('У вас пока что рефералов зарабатывающих на стейкинге')};
            default:
                return {name: '', emptyText: ''};
        }
    }

    useEffect(()=>{
        if (currentTableContent === 'all'){
            dispatch(getRefLogs('all'));
        } else if (currentTableContent === 'bot'){
            dispatch(getRefLogs('bot'));
        } else if (currentTableContent === 'staking'){
            dispatch(getRefLogs('staking'));
        }
    }, [currentTableContent, dispatch])

    return (
        <section className={`${Styles.container} `}>
            <div className={`${Styles.filterWrap}`}>
                <div className={`${Styles.textBox}`}>
                    <div className={`${Styles.textBoxItem}`}>{t('Количество приглашенных')}: <span>{refMainData?.referrals}</span></div>
                    <div className={`${Styles.textBoxItem}`}>{t('Внесли депозит')}: <span>{refMainData?.referrals_with_deposit}</span></div>
                </div>
                <div className={`${Styles.filterBox}`}>
                    <div className={`${Styles.filterBoxTitle}`}>{t('Фильтр')}:</div>
                    <div className={`${Styles.filterBoxContainer}`}>
                        <div
                            className={`${Styles.filterBoxContainerItem} ${currentTableContent === 'all' ? Styles.filterBoxContainerItemActive : null}`}
                            onClick={()=>{setCurrentTableContent('all')}}
                        >
                            {t('Все')}
                        </div>
                        <div
                            className={`${Styles.filterBoxContainerItem} ${currentTableContent === 'bot' ? Styles.filterBoxContainerItemActive : null}`}
                            onClick={()=>{setCurrentTableContent('bot')}}
                        >
                            {t('Бот')}
                        </div>
                        <div
                            className={`${Styles.filterBoxContainerItem} ${currentTableContent === 'staking' ? Styles.filterBoxContainerItemActive : null}`}
                            onClick={()=>{setCurrentTableContent('staking')}}
                        >
                            {t('Стейкинг')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${Styles.tableWrap} ${Styles.honeeCard}`}>
                <div className={`${Styles.honeeCardBody}`}>
                    <div className={`${Styles.cardBodyWithScroll}`}>
                        <div className={`${Styles.titleRow}`}>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Имя')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemD}`}>{t('Уровень')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Сумма депозита')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray}`}>{t('Заработок')}</div>
                            <div className={`${Styles.title6} ${Styles.colorGray} ${Styles.titleRowItemD}`}>{t('Дата')}</div>
                        </div>
                        <div className={`${Styles.contentBox}`} onScroll={onScroll}>
                            {refLogsCorrectData?.[currentTableContent]?.length > 0 ? refLogsCorrectData?.[currentTableContent].map((item, index)=>{
                                return (
                                    <div className={`${Styles.contentBoxItem} ${item.deposit ? null : Styles.contentBoxItemWithoutDep}`} key={'profitTable' + index}>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Имя')}</div>
                                            <div className={`${Styles.contentBoxItemWrapValue} ${Styles.contentBoxItemWrapValueOverfloading}`}>{item.email}</div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Уровень')}</div>
                                            <div className={`${Styles.contentBoxItemWrapValue} ${Styles.contentBoxItemWrapValueAbsDisplayed}`}>
                                                <div className={`${Styles.partners}`}>
                                                    {item.partners.map((item, index) => {
                                                        return (
                                                            <div className={`${Styles.partnersRow}`}
                                                                 key={'partners' + index}>
                                                                <div className={`${Styles.level}`}>{index + 1} {t('Уровень')}
                                                                </div>
                                                                <div className={`${Styles.value}`}>{item}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                {item.level}
                                            </div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Сумма депозита')}</div>
                                            <div className={`${Styles.contentBoxItemWrapValue}`}>{item.bonus * 1} {item?.currency?.short_name ? item?.currency?.short_name : "USDT"}</div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Заработок')}</div>
                                            <div className={`${Styles.contentBoxItemWrapValue}`}>{item.earning ? item.earning : 0} {item?.currency?.short_name ? item?.currency?.short_name : "USDT"}</div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemWrap}`}>
                                            <div className={`${Styles.contentBoxItemWrapName} ${Styles.title6} ${Styles.colorGray}`}>{t('Дата')}</div>
                                            <div className={`${Styles.contentBoxItemWrapValue}`}><Moment format="DD/MM/YYYY">{item.registered}</Moment></div>
                                        </div>
                                    </div>
                                )
                            }) : <EmptyValues text={originHandler().emptyText} withIcon={false} withBtn={false}/>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BonusesProfit;
