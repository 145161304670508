import React, {useEffect} from "react";
import Styles from '../styles.module.scss'
import {useTranslation} from "react-i18next";
import Seo from "../../../components/_UI/seo";
import Fade from 'react-reveal/Fade';


const WhitePaperOmtEcosystem = () => {
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Ecosystem')}/>
            <div className={`${Styles.contentWrap}`}>
                <Fade top>
                    <div className={`${Styles.title1} ${Styles.colorOrange}`}>Oracle Meta Technologies Ecosystem</div>
                </Fade>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    Oracle provides its users with innovative products that include the most secure
                                    wallet and an integrated AI-based trading bot for cryptocurrencies.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The AI bot is designed to make informed decisions based on market data and
                                    trends. By leveraging advanced technologies, this bot automatically scans the
                                    market and provides detailed analytical data that helps users identify the most
                                    profitable investment opportunities. It also allows users to create their own
                                    trading strategies that can be tested in real-time conditions.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade left>
                                <div className={`${Styles.paragraph}`}>
                                    A crucial aspect of security for any cryptocurrency owner is the secure storage
                                    and management of their assets. To address this, Oracle has developed its
                                    wallet, which ensures a high level of security and user-friendliness. With this
                                    wallet, users can store, send, and receive various cryptocurrencies, as well as
                                    view their transaction history.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade right>
                                <div className={`${Styles.paragraph}`}>
                                    The Oracle wallet also supports staking, enabling users to earn rewards for
                                    holding their coins in the wallet.
                                </div>
                            </Fade>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <Fade bottom>
                                <div className={`${Styles.paragraph}`}>
                                    Additionally, Oracle is constantly working on developing new features and
                                    enhancing existing ones to meet the needs of its users and remain at the
                                    forefront of cryptocurrency innovations.
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhitePaperOmtEcosystem;