import React from "react";
import Styles from './styles.module.scss'



const PagIcon = (props) => {
    const {type, isHover} = props;

    const contentHandler = () =>{
        switch (type){
            case 'prev':
                return (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.8327 7.49996H1.16602M1.16602 7.49996L6.99935 13.3333M1.16602 7.49996L6.99935 1.66663"
                            stroke={isHover ? "#F7931A" : "#F5F5F5"}
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </svg>
                );
            case 'next':
                return (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.16602 7.49996H12.8327M12.8327 7.49996L6.99935 1.66663M12.8327 7.49996L6.99935 13.3333"
                            stroke={isHover ? "#F7931A" : "#F5F5F5"}
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                );
            default:
                return null
        }
    }

    return (
        <section className={`${Styles.container}`}>
            {contentHandler()}
        </section>
    );
};

export default PagIcon;
