import React from "react";
import Marquee from "react-fast-marquee";
import Styles from './styles.module.scss';

import cryptoIcon1 from '../../theme/assets/svg/cryptoM1.svg';
import cryptoIcon2 from '../../theme/assets/svg/cryptoM2.svg';
import cryptoIcon3 from '../../theme/assets/svg/cryptoM3.svg';
import cryptoIcon4 from '../../theme/assets/svg/cryptoM4.svg';
import cryptoIcon5 from '../../theme/assets/svg/cryptoM5.svg';



const CryptoMarquee = () => {

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap}`}>
                <Marquee speed={25}>
                    <div className={`${Styles.contentWrapItem}`}>
                        <img src={cryptoIcon1} alt="Binance Smart Chain"/>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <img src={cryptoIcon2} alt="Solana"/>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <img src={cryptoIcon3} alt="Ethereum"/>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <img src={cryptoIcon4} alt="Near"/>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <img src={cryptoIcon5} alt="Crypto News"/>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <img src={cryptoIcon1} alt="Binance Smart Chain"/>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <img src={cryptoIcon2} alt="Solana"/>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <img src={cryptoIcon3} alt="Ethereum"/>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <img src={cryptoIcon4} alt="Near"/>
                    </div>
                    <div className={`${Styles.contentWrapItem}`}>
                        <img src={cryptoIcon5} alt="Crypto News"/>
                    </div>
                </Marquee>
            </div>
        </section>
    );
};

export default CryptoMarquee;
