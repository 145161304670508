import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import StakingMain from "../../components/stakingMain";
import StakingAvailable from "../../components/stakingAvailable";
import StakingInWork from "../../components/stakingInWork";
import StakingForm from "../../components/stakingForm";
import StakingBalance from "../../components/stakingBalance";
import {getAvailableStaking} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";
import StakingWithdrawForm from "../../components/stakingWithdrawForm";
// import StakingFormOmt from "../../components/stakingFormOmt";


const StakingPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getAvailableStaking());
        // dispatch(getWalletAssets());
        // dispatch(getOneCurrentWallet(9));
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={t('Стейкинг')}/>
            <div className={`${Styles.contentWrap}`}>
                <StakingMain/>
                <StakingAvailable/>
                <StakingInWork/>
                <StakingForm/>
                <StakingWithdrawForm/>
                <StakingBalance/>
                {/*<StakingFormOmt/>*/}
            </div>
        </section>
    );
};

export default StakingPage;