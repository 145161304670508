import React from "react";
import Styles from './styles.module.scss';

import omt20Icon from '../../../theme/assets/crypto/omt_crypto_omt20_icon.svg';
import btcIcon from '../../../theme/assets/crypto/omt_crypto_btc_icon.svg';
import ltcIcon from '../../../theme/assets/crypto/omt_crypto_icon_ltc.svg';
import bnbIcon from '../../../theme/assets/crypto/omt_crypto_bnb_icon.svg';
import ethIcon from '../../../theme/assets/crypto/omt_crypto_eth_icon.svg';
import solIcon from '../../../theme/assets/crypto/omt_crypto_sol_icon.svg';
import xrpIcon from '../../../theme/assets/crypto/omt_crypto_xrp_icon.svg';
import tronIcon from '../../../theme/assets/crypto/omt_crypto_tron_icon.svg';
import omtIcon from '../../../theme/assets/crypto/omt_crypto_omt_icon.svg';
import honeeIcon from '../../../theme/assets/crypto/omt_crypto_honee_icon.svg';
import usdtBrcIcon from '../../../theme/assets/crypto/omt_crypto_icon_brc.svg';
import usdtErcIcon from '../../../theme/assets/crypto/omt_crypto_icon_erc.svg';
import usdtTrcIcon from '../../../theme/assets/crypto/omt_crypto_icon_trc.svg';
import usdcIcon from '../../../theme/assets/crypto/omt_crypto_icon_usdc.svg';
import adaIcon from '../../../theme/assets/crypto/omt_crypto_ada_icon.svg';
import maticIcon from '../../../theme/assets/crypto/omt_crypto_matic_icon.svg';
import avalancheIcon from '../../../theme/assets/crypto/omt_crypto_avalanche_icon.svg';
import litecoinIcon from '../../../theme/assets/crypto/omt_crypto_litecoin_icon.svg';
import polkadotIcon from '../../../theme/assets/crypto/omt_crypto_polkadot_icon.svg';

import xrpErc20Icon from '../../../theme/assets/crypto/omt_crypto_xrp_erc20_icon.svg';
import wethErc20Icon from '../../../theme/assets/crypto/omt_crypto_weth_erc20_icon.svg';
// import usdtErc20Icon from '../../../theme/assets/crypto/omt_crypto_usdt_erc20_icon.svg';
import wethPolIcon from '../../../theme/assets/crypto/omt_crypto_weth_pol_icon.svg';
import usdtPolIcon from '../../../theme/assets/crypto/omt_crypto_usdt_pol_icon.svg';
import polIcon from '../../../theme/assets/crypto/omt_crypto_pol_icon.svg';
import polBep20Icon from '../../../theme/assets/crypto/omt_crypto_pol_bep20_icon.svg';

// SOL, NEAR, LINK, ADA, AVAX, SUI, FIL, ARB, APT, MATIC, ATOM, DOT, DOGE, TON

import nSolIcon from '../../../theme/assets/crypto/omt_crypto_new_SOL_icon.svg';
import nNearIcon from '../../../theme/assets/crypto/omt_crypto_new_Near_icon.svg';
import nLinkIcon from '../../../theme/assets/crypto/omt_crypto_new_Chainlink_icon.svg';
import nAdaIcon from '../../../theme/assets/crypto/omt_crypto_new_ADA_icon.svg';
import nAvaxIcon from '../../../theme/assets/crypto/omt_crypto_new_Avalanche_icon.svg';
import nSuiIcon from '../../../theme/assets/crypto/omt_crypto_new_SUI_icon.svg';
import nFilIcon from '../../../theme/assets/crypto/omt_crypto_new_FIL_icon.svg';
import nArbIcon from '../../../theme/assets/crypto/omt_crypto_new_ARB_icon.svg';
import nAptIcon from '../../../theme/assets/crypto/omt_crypto_new_APT_icon.svg';
import nMaticIcon from '../../../theme/assets/crypto/omt_crypto_new_MATIC_icon.svg';
import nAtomIcon from '../../../theme/assets/crypto/omt_crypto_new_ATOM_icon.svg';
import nDotIcon from '../../../theme/assets/crypto/omt_crypto_new_DOT_icon.svg';
import nDogeIcon from '../../../theme/assets/crypto/omt_crypto_new_DOGE_icon.svg';
import nTonIcon from '../../../theme/assets/crypto/omt_crypto_new_TON_icon.svg';



const CryptoIcon = (props) => {
    const {short_name, bigSize, indexSize} = props;


    const contentHandler = () =>{
        switch (short_name){
            case 'LTC_BEP20':
                return (
                    <img src={litecoinIcon} alt="LTC_BEP20"/>
                );
            case 'LTC':
                return (
                    <img src={ltcIcon} alt="LTC"/>
                );
            case 'AVAX_BEP20':
                return (
                    <img src={avalancheIcon} alt="AVAX_BEP20"/>
                );
            case 'DOT_BEP20':
                return (
                    <img src={polkadotIcon} alt="DOT_BEP20"/>
                );
            case 'BTC':
                return (
                    <img src={btcIcon} alt="btc"/>
                );
            case 'BNB':
                return (
                    <img src={bnbIcon} alt="bnb"/>
                );
            case 'ETH':
                return (
                    <img src={ethIcon} alt="eth"/>
                );
            case 'SOL_BEP20':
                return (
                    <img src={solIcon} alt="sol"/>
                );
            case 'XRP':
                return (
                    <img src={xrpIcon} alt="xrp"/>
                );
            case 'TRX':
                return (
                    <img src={tronIcon} alt="trx"/>
                );
            case 'USDT_TRC20':
                return (
                    <img src={usdtTrcIcon} alt="USDT_TRC20"/>
                );
            case 'OMT':
                return (
                    <img src={omtIcon} alt="omt"/>
                );
            case 'OMT20':
                return (
                    <img src={omt20Icon} alt="omt20"/>
                );
            case 'OMT_BEP20':
                return (
                    <img src={omtIcon} alt="omt"/>
                );
            case 'HONEE':
                return (
                    <img src={honeeIcon} alt="honee"/>
                );
            case 'USDT_BEP20':
                return (
                    <img src={usdtBrcIcon} alt="brc"/>
                );
            case 'USDT_ERC20':
                return (
                    <img src={usdtErcIcon} alt="erc"/>
                );
            case 'USDC_BEP20':
                return (
                    <img src={usdcIcon} alt="usdc"/>
                );
            case 'MATIC_BEP20':
                return (
                    <img src={maticIcon} alt="usdc"/>
                );
            case 'POL_20':
                return (
                    <img src={maticIcon} alt="usdc"/>
                );
            case 'ADA_BEP20':
                return (
                    <img src={adaIcon} alt="usdc"/>
                );
            case 'SOL':
                return (
                    <img src={nSolIcon} alt="SOL"/>
                );
            case 'NEAR':
                return (
                    <img src={nNearIcon} alt="NEAR"/>
                );
            case 'LINK':
                return (
                    <img src={nLinkIcon} alt="LINK"/>
                );
            case 'ADA':
                return (
                    <img src={nAdaIcon} alt="ADA"/>
                );
            case 'AVAX':
                return (
                    <img src={nAvaxIcon} alt="AVAX"/>
                );
            case 'SUI':
                return (
                    <img src={nSuiIcon} alt="SUI"/>
                );
            case 'FIL':
                return (
                    <img src={nFilIcon} alt="FIL"/>
                );
            case 'ARB':
                return (
                    <img src={nArbIcon} alt="ARB"/>
                );
            case 'APT':
                return (
                    <img src={nAptIcon} alt="APT"/>
                );
            case 'MATIC':
                return (
                    <img src={nMaticIcon} alt="MATIC"/>
                );
            case 'ATOM':
                return (
                    <img src={nAtomIcon} alt="ATOM"/>
                );
            case 'DOT':
                return (
                    <img src={nDotIcon} alt="DOT"/>
                );
            case 'DOGE':
                return (
                    <img src={nDogeIcon} alt="DOGE"/>
                );
            case 'TON':
                return (
                    <img src={nTonIcon} alt="TON"/>
                );
            case 'XRP_ERC20':
                return (
                    <img src={xrpErc20Icon} alt="XRP_ERC20"/>
                );
            case 'WETH_ERC20':
                return (
                    <img src={wethErc20Icon} alt="WETH_ERC20"/>
                );
            case 'WETH_POL20':
                return (
                    <img src={wethPolIcon} alt="WETH_POL20"/>
                );
            case 'USDT_POL20':
                return (
                    <img src={usdtPolIcon} alt="USDT_POL20"/>
                );
            case 'POL':
                return (
                    <img src={polIcon} alt="POL"/>
                );
            case 'POL_BEP20':
                return (
                    <img src={polBep20Icon} alt="POL_BEP20"/>
                );
            default:
                return (
                    <img src={solIcon} alt="sol"/>
                );
        }
    }

    return (
        <div className={`${Styles.container} ${bigSize ? Styles.containerBig : null} ${indexSize ? Styles.containerIndex : null}`}>
            {contentHandler()}
        </div>
    );
};

export default CryptoIcon;
